import React from 'react';
import TeamComponent from '../TeamComponent';

function TeamContainer() {
    return (
        <div>
            <h2 className="text-2xl font-bold mb-4">Team</h2>
            {/* Ajouter les composants d'utilisateur */}
            <TeamComponent name="Utilisateur A" role="Admin" />
            <TeamComponent name="Utilisateur B" role="Éditeur" />
        </div>
    );
}

export default TeamContainer;
