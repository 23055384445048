import React, { useState } from 'react';
import Typography from '../../../../style/Typography';

function PlaceholdersHeader({ documentData }) {
    // Récupération du nom du modèle depuis documentData
    const modelName = documentData?.name || 'Nom du modèle non disponible'; // Valeur par défaut si le nom n'est pas défini

    const [selectedMode, setSelectedMode] = useState('Génération unique');
    const buttonOptions = ['Génération unique', 'Génération multiple'];

    const handleSelect = (option) => {
        setSelectedMode(option);
    };

    return (
        <div className="sticky top-0 border-b border-gray-200 text-center space-y-2 z-10 p-4">
            {/* Affiche le nom du modèle */}
            <Typography
                variant="lead"
                weight='bold'
            >
                {modelName}
            </Typography>

            {/* Boutons radio */}
            <div className="flex justify-center space-x-4">
                {buttonOptions.map((option, index) => (
                    <label key={index} className="flex items-center space-x-1 cursor-pointer">
                        <input
                            type="radio"
                            name="generationMode"
                            value={option}
                            checked={selectedMode === option}
                            onChange={() => handleSelect(option)}
                            className="form-radio text-primary-600"
                        />
                        <span className={`${selectedMode === option ? 'text-primary-600 font-bold' : 'text-gray-700'} text-sm`}>
                            {option}
                        </span>
                    </label>
                ))}
            </div>
        </div>
    );
}

export default PlaceholdersHeader;
