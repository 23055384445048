import React from 'react';
import AdminBoardComponent from '../AdminBoardComponent';

function AdminBoardContainer() {
    return (
        <div>
            <h2 className="text-2xl font-bold mb-4">Dashboard</h2>
            {/* Ajoute les composants individuels du tableau de bord ici */}
            <AdminBoardComponent />
        </div>
    );
}

export default AdminBoardContainer;
