import React from 'react';
import { Modal } from 'flowbite-react';
import PropTypes from 'prop-types';
import Button from '../../Button';

function GenerateDocumentOkModal({ 
    isOpen, 
    onClose, 
    title, 
    bodyContent, 
    onConfirm, 
    onReset, 
    onGoBack, 
    confirmText = 'Confirm', 
    cancelText = 'Cancel',
    isUserLoggedIn = false // Ajout d'un indicateur pour vérifier si l'utilisateur est inscrit
}) {
    return (
        <Modal show={isOpen} onClose={onClose}>
            <Modal.Header>{title}</Modal.Header>
            <Modal.Body>
                <div className="space-y-6">
                    {bodyContent}

                    {/* Message d'invitation à l'inscription */}
                    {!isUserLoggedIn && (
                        <p className="text-sm text-gray-500">
                            Vous n'êtes pas encore inscrit ? Inscrivez-vous dès maintenant pour accéder à des fonctionnalités plus avancées !
                        </p>
                    )}
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="primary" size="md" onClick={onReset}>
                    Refaire le même document
                </Button>
                <Button variant="tertiary" size="md" onClick={onGoBack}>
                    Quitter
                </Button>
            </Modal.Footer>
        </Modal>
    );
}

GenerateDocumentOkModal.propTypes = {
    isOpen: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    title: PropTypes.string,
    bodyContent: PropTypes.node,
    onConfirm: PropTypes.func,
    onReset: PropTypes.func, // Ajout de la prop onReset
    onGoBack: PropTypes.func, // Ajout de la prop onGoBack
    confirmText: PropTypes.string,
    cancelText: PropTypes.string,
    isUserLoggedIn: PropTypes.bool, // Indicateur pour l'état de connexion de l'utilisateur
};

export default GenerateDocumentOkModal;
