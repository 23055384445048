// src/components/ProtectedRoutes/ProtectedRoute.jsx

import React from 'react';
import { Navigate } from 'react-router-dom';
import { useUser } from '../../../context/User/UserContext';

export function ProtectedRoute({ children }) {
  const { user, isLoading } = useUser();

  if (isLoading) {
    // Vous pouvez afficher un spinner de chargement ici si vous le souhaitez
    return <div>Chargement...</div>;
  }

  if (!user) {
    // Si l'utilisateur n'est pas connecté, redirigez-le vers la page de connexion ou la page d'accueil
    return <Navigate to="/" replace />;
  }

  // Si l'utilisateur est connecté, affichez le composant enfant
  return children;
}
