import { RiFacebookFill, RiLinkedinFill, RiYoutubeFill } from "react-icons/ri";

const footerApplicationLinks = [
    {
        label: "Accueil",
        baseUrl: "/",
        type: "internal",
    },
    {
        label: "Bara Doc",
        baseUrl: "/#",
        type: "internal"
    },
    {
        label: "Bibliothèque",
        baseUrl: "/document-manager",
        type: "internal"
    },
    {
        label: "Guide",
        baseUrl: "/how-it-works",
        type: "internal"
    },
];
const footerUsersLinks = [
    {
        label: "Mon espace",
        baseUrl: "/#",
        type: "internal"
    },
    {
        label: "Connexion",
        baseUrl: "/connexion",
        type: "internal"
    },
    {
        label: "Inscription",
        baseUrl: "/connexion/inscription",
        type: "internal"
    },
    {
        label: "Mot de passe oublié",
        baseUrl: "/connexion/mot-de-passe-perdu",
        type: "internal"
    },
];
const footerInformationLinks = [
    {
        label: "CGU",
        baseUrl: "/terms-and-conditions",
        type: "internal"
    },
    {
        label: "Confidentialité",
        baseUrl: "/privacy-policy",
        type: "internal"
    },
    {
        label: "À propos",
        baseUrl: "/#",
        type: "internal"
    },
    {
        label: "Contact",
        baseUrl: "/#",
        type: "internal"
    }
];
export const footerSocialNetworksLinks = [
    {
        label: "Youtube",
        baseUrl: "https://www.youtube.com/",
        type: "external",
        icon: RiYoutubeFill,
    },
    {
        label: "Linkedin",
        baseUrl: "https://www.linkedin.com/in/marc-eliecharleston/",
        type: "external",
        icon: RiLinkedinFill,
    },
    {
        label: "Facebook",
        baseUrl: "https://www.facebook.com/marcelie.charleston",
        type: "external",
        icon: RiFacebookFill,
    },
];
export const footerLinks = [
    {
        label: "App",
        links : footerApplicationLinks,
    },
    {
        label: "Utilisateurs",
        links : footerUsersLinks,
    },
    {
        label: "Informations",
        links : footerInformationLinks,
    },
    {
        label: "Réseaux",
        links : footerSocialNetworksLinks,
    },
];
