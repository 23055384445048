import React, { useState, useEffect } from 'react';

const EditDocumentForm = ({ model, onSave, onCancel }) => {
    const [name, setName] = useState(model?.name || '');
    const [category, setCategory] = useState(model?.category || '');
    const [subcategory, setSubcategory] = useState(model?.subcategory || '');
    const [language, setLanguage] = useState(model?.language || '');
    const [description, setDescription] = useState(model?.description || '');

    // Utiliser `useEffect` pour initialiser les valeurs du formulaire lors du chargement du modèle
    useEffect(() => {
        setName(model?.name || '');
        setCategory(model?.category || '');
        setSubcategory(model?.subcategory || '');
        setLanguage(model?.language || '');
        setDescription(model?.description || '');
    }, [model]);

    const handleSubmit = (e) => {
        e.preventDefault();
        const updatedData = { 
            id: model?.id,
            name, 
            category: category || '',  
            subcategory: subcategory || '',
            language: language || '',
            description: description || ''
        };
        console.log("Données envoyées au parent pour la mise à jour :", updatedData);
        onSave(updatedData);
    };

    return (
        <form onSubmit={handleSubmit} className="space-y-4">
            <div>
                <label>Nom du document</label>
                <input 
                    type="text" 
                    value={name} 
                    onChange={(e) => setName(e.target.value)} 
                    className="border p-2 rounded w-full" 
                />
            </div>

            <div>
                <label>Catégorie</label>
                <input 
                    type="text" 
                    value={category} 
                    onChange={(e) => setCategory(e.target.value)} 
                    className="border p-2 rounded w-full" 
                />
            </div>

            <div>
                <label>Sous-catégorie</label>
                <input 
                    type="text" 
                    value={subcategory} 
                    onChange={(e) => setSubcategory(e.target.value)} 
                    className="border p-2 rounded w-full" 
                />
            </div>

            <div>
                <label>Langue</label>
                <input 
                    type="text" 
                    value={language} 
                    onChange={(e) => setLanguage(e.target.value)} 
                    className="border p-2 rounded w-full" 
                />
            </div>

            <div>
                <label>Description</label>
                <textarea 
                    value={description} 
                    onChange={(e) => setDescription(e.target.value)} 
                    className="border p-2 rounded w-full" 
                />
            </div>

            <div className="flex space-x-4">
                <button type="submit" className="bg-blue-500 text-white p-2 rounded">Enregistrer</button>
                <button type="button" onClick={onCancel} className="bg-gray-500 text-white p-2 rounded">Annuler</button>
            </div>
        </form>
    );
};

export default EditDocumentForm;
