import React from 'react';

const SidePanel = ({ isOpen, onClose, title, children }) => {
    return (
        <div
            className={`fixed right-0 w-1/3 bg-white shadow-lg transform transition-transform ${
                isOpen ? 'translate-x-0' : 'translate-x-full'
            }`}
            style={{
                zIndex: 1000,
                top: '60px', // Décalage depuis le haut
                height: 'calc(100% - 60px)' // Hauteur ajustée en fonction du header
            }}
        >
            {/* Header du SidePanel avec le titre et un bouton de fermeture */}
            <div className="p-4 border-b flex justify-between items-center">
                <h2 className="text-xl font-semibold">{title}</h2>
                <button onClick={onClose} className="text-gray-500 hover:text-gray-700">
                    X
                </button>
            </div>

            {/* Contenu du SidePanel */}
            <div className="p-4 overflow-y-auto">
                {children}
            </div>
        </div>
    );
};

export default SidePanel;
