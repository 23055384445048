import React from 'react';
import ButtonSelect from '../../../../../style/ButtonSelect';
import Typography from '../../../../../style/Typography';

function Select({ name, choices, value, onChange }) {
    // If choices is undefined or empty, log a warning
    if (!choices || choices.length === 0) {
        console.warn('Select component - No choices available!');
        return (
            <Typography 
                variant="body-sm" 
                theme="gray-600"
                className="italic"
            >
                Aucun choix disponible
            </Typography>
        );
    }

    return (
        <div className="mb-4 w-full flex flex-col items-center ">
            <ButtonSelect
                options={choices}
                selectedValue={value}
                onSelect={(selectedOption) => {
                    console.log('ButtonSelect - Option selected:', selectedOption);
                    onChange(name, selectedOption);  // Assurez-vous que `name` est bien passé ici
                }}
                />

        </div>
    );
}

export default Select;
