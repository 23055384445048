import React from 'react';
import TextArea from '../TextArea';
import Select from '../Select';
import Gender from '../Gender';
import Text from '../Text';

function Option({ name, placeholders, onChange, userInputs }) {
    return (
        <div className="mb-4">
            {/* Affiche le nom de l'option avec l'indicateur "Option" */}
            <label className="block font-semibold mb-2">
                {name} <span className="text-sm font-normal">(Option)</span>
            </label>

            {/* Boucle sur les placeholders de l'option et les affiche en fonction de leur type */}
            {placeholders.map((placeholder, index) => {
                const placeholderValue = userInputs[placeholder.name]?.value || ''; // Récupère la valeur depuis userInputs
                const label = placeholder.is_enterprise ? "Enterprise" : placeholder.is_employee ? "Employee" : "Normal";

            
                switch (placeholder.type) {
                    case 'text':
                        return (
                            <Text
                                key={index}
                                name={placeholder.name}
                                value={placeholderValue}
                                onChange={(name, value) => onChange(name, value)}
                                label={label}
                            />
                        );

                    case 'textarea':
                        return (
                            <TextArea
                                key={index}
                                name={placeholder.name}
                                value={placeholderValue}
                                onChange={(name, value) => onChange(name, value)}
                                label={label}
                            />
                        );

                    case 'select':
                        return (
                            <Select
                                key={index}
                                name={placeholder.name}
                                choices={placeholder.data || []}
                                value={placeholderValue}
                                onChange={(name, value) => onChange(name, value)}
                                label={label}
                            />
                        );

                    case 'gender':
                        return (
                            <Gender
                                key={index}
                                name={placeholder.name}
                                value={placeholder.value || ''}
                                options={placeholder.options || ['Madame', 'Monsieur']}
                                onChange={(name, value) => onChange(name, value)}
                                label={label}
                            />
                        );

                    default:
                        return null;
                }
            })}
        </div>
    );
}

export default Option;
