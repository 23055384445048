import React from 'react';

function UsersModelsComponent({ name, category, subcategory, team }) {
    return (
        <div className="p-4 border rounded shadow-sm mb-4 flex justify-between items-center">
            <div>
                <p><strong>Nom du modèle:</strong> {name}</p>
                <p><strong>Catégorie:</strong> {category}</p>
                <p><strong>Sous-catégorie:</strong> {subcategory}</p>
                <p><strong>Team:</strong> {team}</p>
            </div>
            <button className="p-2 bg-gray-200 rounded">Action</button>
        </div>
    );
}

export default UsersModelsComponent;
