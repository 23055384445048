import React from 'react';

function TeamComponent({ name, role }) {
    return (
        <div className="p-4 border rounded shadow-sm mb-4">
            <p><strong>Nom:</strong> {name}</p>
            <p><strong>Rôle:</strong> {role}</p>
            <p><strong>Pôle:</strong> {role}</p>
        </div>
    );
}

export default TeamComponent;
