import React from 'react';
import { HowItWorksContainer } from '../../components/Containers/HowItWorksContainer';
import content from '../../assets/jsonfile/howItWorksContent.json';
import Typography from '../../components/style/Typography';

const HowItWorks = () => {
    return (
        <HowItWorksContainer>
            <Typography variant="h1" className="mb-8 text-center">
                Comment ça marche ?
            </Typography>

            {content.map((section) => (
                <div
                    key={section.id}
                    className="flex flex-col gap-6 md:flex-row mb-12 bg-gray-50 rounded-lg p-6 shadow-sm border border-gray-200"
                >
                    {/* Texte explicatif */}
                    <div className="w-full md:w-2/3">
                        <Typography
                            variant="h4"
                            className="mb-4 text-primary-600 break-words"
                        >
                            {section.id}. {section.icon} {section.title}
                        </Typography>
                        {section.code && (
                            <Typography
                                variant="body-sm"
                                className="mb-2"
                            >
                                <code>{section.code}</code>
                            </Typography>
                        )}
                        <Typography
                            variant="body-base"
                            className="mb-2"
                        >
                            <strong>Quand l'utiliser ?</strong> : {section.when}
                        </Typography>
                        {section.how && (
                            <Typography
                                variant="body-base"
                                className="mb-2"
                            >
                                <strong>Comment l'écrire ?</strong> : {section.how}
                            </Typography>
                        )}
                        <Typography
                            variant="body-base"
                            className="mb-2"
                        >
                            <strong>Fonctionnement :</strong> {section.functionality}
                        </Typography>
                        {section.note && (
                            <Typography
                                variant="caption3"
                                className="text-gray-500 italic"
                            >
                                Note : {section.note}
                            </Typography>
                        )}
                        {section.example && (
                            <Typography
                                variant="body-sm"
                                className="mt-2"
                            >
                                Exemple :
                                <pre className="bg-gray-100 rounded-lg p-2">
                                    {section.example}
                                </pre>
                            </Typography>
                        )}
                    </div>

                    {/* Espace pour le GIF ou la vidéo */}
                    <div className="w-full md:w-1/3 flex justify-center items-center">
                        <div className="w-40 h-40 md:w-48 md:h-48 rounded-lg bg-gray-200 flex items-center justify-center shadow">
                            <Typography
                                variant="caption3"
                                className="text-gray-500 text-center"
                            >
                                Ajouter un GIF ou une vidéo ici
                            </Typography>
                        </div>
                    </div>
                </div>
            ))}
        </HowItWorksContainer>
    );
};

export default HowItWorks;
