import React from 'react';
import Typography from '../../../../../style/Typography';

function Text({ name, value, onChange, label}) {
    return (
        <div className="mb-4 w-full flex flex-col justify-center"> {/* Assure que le conteneur prend toute la largeur */}
            
            {/* Label avec Typography */}
            <Typography
                variant="body-base"
                theme="gray-700"
                className="block w-[70%] mx-auto mb-1"
            >
                {name}
            </Typography>
            <input
                type="text"
                defaultValue={value || ''}
                onBlur={(e) => onChange(name, e.target.value)} // Utilisation correcte de `onChange`
                className="w-[70%] text-sm mx-auto border border-gray-300 bg-gray-50 px-2 py-1 rounded focus:border-primary focus:shadow-md"
                placeholder={name}
                label={label}
            />
        </div>
    );
}

export default Text;
