import React, { useState } from 'react';
import Loader from '../../style/Loader';
import Typography from '../../style/Typography';
import Button from '../../style/Button';
import FooterPagination from '../../style/FooterPagination';

import { IoAddOutline, IoDocumentText } from "react-icons/io5";
import { FaArrowRightFromBracket } from "react-icons/fa6";

function DocumentTableHome({ documents, 
    isModalLoading, 
    onSelectDocument, 
    onGenerateDocument,
    onSelectShowOptionsModal, 
    onAddShowOptionsModal, }
) {
    const [currentPage, setCurrentPage] = useState(1);
    const [selectedDocumentId, setSelectedDocumentId] = useState(null);

    const documentsPerPage = 10;

    const handlePageChange = (page) => {
        setCurrentPage(page);
    };

    const paginatedDocuments = documents.slice(
        (currentPage - 1) * documentsPerPage,
        currentPage * documentsPerPage
    );

    const handleSelectDocumentWithCondition = (document) => {
        // Vérifie si le document n'a pas d'options dans document_metadata
        if (!document.document_metadata || !document.document_metadata.options || document.document_metadata.options.length === 0) {
            onGenerateDocument(document);
        } else {
            onSelectShowOptionsModal(document);
            // Optionnel: Affichez une alerte ou un message d’information à l’utilisateur
        }
    };
    

    return (
        <div className="h-full flex flex-col">
            {/* Header avec le bouton "Nouveau" */}
            <div className="flex justify-end items-center py-4 px-4">
                <Button
                    variant="tertiary"
                    size="sm"
                    icon={IoAddOutline}
                    iconPosition="right"
                    onClick={() => onAddShowOptionsModal()}
                >
                    Nouveau
                </Button>
            </div>
    
    
            {/* Contenu de la table */}
            <div className="flex-grow overflow-auto px-4 py-2">
                <div className="border border-gray-300 rounded-lg shadow-md overflow-hidden">
                    <table className="w-full table-fixed border-collapse bg-white">
                        {/* En-tête du tableau */}
                        <thead className="bg-gray-100 sticky top-0">
                            <tr>
                                <th className="px-6 py-4 text-center w-3/5 max-w-[300px] truncate whitespace-nowrap overflow-hidden rounded-tl">
                                    <Typography
                                        variant="body-base"
                                        weight="medium"
                                        theme="gray-600"
                                        className="truncate whitespace-nowrap overflow-hidden font-bold"
                                    >
                                        Nom du document
                                    </Typography>
                                </th>
                                <th className="px-6 py-4 text-left w-2/5 max-w-[200px] truncate whitespace-nowrap overflow-hidden">
                                    <Typography
                                        variant="body-base"
                                        weight="medium"
                                        theme="gray-600"
                                        className="truncate whitespace-nowrap overflow-hidden"
                                    >
                                        Catégorie
                                    </Typography>
                                </th>
                                <th className="px-6 py-4 text-left w-1/5 max-w-[250px] whitespace-nowrap overflow-hidden rounded-tr">
                                    <Typography
                                        variant="body-base"
                                        weight="medium"
                                        theme="gray-600"
                                        className="whitespace-nowrap"
                                    >
                                        Générer
                                    </Typography>
                                </th>
                            </tr>
                        </thead>
    
                        {/* Corps du tableau */}
                        <tbody className="border-l border-r border-b border-gray-200">
                            {paginatedDocuments.map((document, index) => (
                                <tr
                                    key={index}
                                    className={`border-b cursor-pointer transition-all duration-150 ${
                                        document.id === selectedDocumentId
                                            ? 'bg-blue-100 scale-105 shadow-md'
                                            : 'hover:bg-gray-100'
                                    }`}
                                    onClick={() => {
                                        setSelectedDocumentId(document.id);
                                        onSelectDocument(document);
                                    }}
                                >
                                    <td className="px-6 py-3 text-base">
                                        <Typography
                                            variant="caption3"
                                            weight="medium"
                                            theme="gray-700"
                                            className="flex items-center space-x-4 min-h-[24px]"
                                        >
                                            <IoDocumentText className="text-blue-600 w-5 h-5 flex-shrink-0" /> {/* Icône/logo */}
                                            <span className="truncate max-w-full">
                                                {document.name}
                                            </span>
                                        </Typography>
                                    </td>
    
                                    {/* Colonne avec les badges */}
                                    <td className="px-6 py-3 text-base truncate">
                                        {document.category &&
                                        document.category !== "À completer" ? (
                                            <span className="inline-block px-2 py-1 text-xs font-medium bg-gray-100 text-gray-800 rounded">
                                                {document.category}
                                            </span>
                                        ) : (
                                            <span className="inline-block px-2 py-1 text-xs font-medium bg-yellow-100 text-yellow-800 rounded">
                                                À compléter
                                            </span>
                                        )}
                                    </td>
    
                                    <td className="px-6 py-3">
                                        <Button
                                            onClick={(e) => {
                                                e.stopPropagation();
                                                handleSelectDocumentWithCondition(
                                                    document
                                                );
                                            }}
                                            className="w-8 h-8 flex items-center justify-center rounded text-black hover:text-white"
                                            variant="secondary"
                                            icon={FaArrowRightFromBracket}
                                            iconSize={16}
                                        />
                                    </td>
                                </tr>
                            ))}
    
                            {/* Ajout de lignes vides pour atteindre 10 */}
                            {Array.from({ length: 10 - paginatedDocuments.length }).map(
                                (_, index) => (
                                    <tr key={`empty-${index}`} className="border-b">
                                        <td className="px-6 py-3 text-base">&nbsp;</td>
                                        <td className="px-6 py-3 text-base">&nbsp;</td>
                                        <td className="px-6 py-3 text-base">&nbsp;</td>
                                    </tr>
                                )
                            )}
                        </tbody>
                    </table>
                </div>
            </div>
    
            {/* Bordure avec padding */}
            <div className="px-8 py-2">
                <div className="border-t border-gray-300"></div>
            </div>
    
            {/* Footer Pagination */}
            <div className="flex-shrink-0">
            <FooterPagination
                currentPage={currentPage}
                totalPages={Math.ceil(documents.length / documentsPerPage)}
                documentsPerPage={documentsPerPage} // Passez documentsPerPage
                documents={documents} // Passez documents pour afficher le total
                onPageChange={handlePageChange}
            />


            </div>
        </div>
    );
    
    
    
}

export default DocumentTableHome;
