import React from 'react';
import { Img } from "react-image";
import Typography from '../../../components/style/Typography';
import Button from '../../../components/style/Button';
import { HomePageContainer } from '../../../components/Containers/HomePageContainer';
import HeroImage from '../../../assets/homepage/hero-image1.jpg';
import { useNavigate } from 'react-router-dom';

const HomePageHero = () => {
    const navigate = useNavigate();

    return (
        <HomePageContainer className="relative pt-12 pb-32 flex flex-col items-center justify-between rounded-2xl bg-gradient-to-b from-primary-200 to-white">
            <div className="flex flex-col md:flex-row items-center justify-between w-full max-w-7xl px-4 sm:px-6 lg:px-10 space-y-8 md:space-y-0">
                

                {/* Texte principal */}
                <div className="space-y-6 max-w-md md:max-w-lg z-10 flex flex-col text-center md:text-left">
                <Typography 
                        variant="h2" 
                        className="text-black drop-shadow-md animate-fadeIn sm:text-5xl md:text-6xl"
                    >
                        <span className="bg-gradient-to-r from-primary-600 to-primary-400 text-transparent bg-clip-text">Simplifiez</span> la création de vos documents RH et <span className="bg-gradient-to-r from-primary-600 to-primary-400 text-transparent bg-clip-text">gagnez du temps</span>  (Bêta) !
                    </Typography>

                    <Typography 
                        variant="body-lg" 
                        theme="gray-600"
                    >
                        Conçu pour vous faire gagner du temps, générez vos documents en quelques clics.
                    </Typography>

                    <div className="flex flex-col md:flex-row md:space-x-6 space-y-4 md:space-y-0 justify-center md:justify-start">
                        <Button 
                            variant="primary" 
                            size="md" 
                            onClick={() => navigate('/document-manager')}
                        >
                            Essayez gratuitement
                        </Button>

                        <Button 
                            variant="secondary"
                            size="md"
                            onClick={() => navigate('/learn-more')}
                        >
                            En savoir plus
                        </Button>
                    </div>
                </div>

                {/* Image */}
                <div className="hidden sm:block relative w-[30%] h-auto rounded-xl overflow-hidden shadow-md bg-white">
                    <Img
                        src={HeroImage}
                        alt="Illustration"
                        className="object-cover w-full h-full"
                        loader={<div>Chargement...</div>}
                        unloader={<div>Image introuvable</div>}
                    />
                </div>
            </div>

            {/* Phrase d'accroche supplémentaire */}
            <div className="mt-10 px-4 text-center">
                <Typography 
                    variant="caption2" 
                    className="text-gray-600 italic"
                >
                    "Créer vos documents n'aura jamais été aussi simple."
                </Typography>
            </div>
        </HomePageContainer>
    );
};

export default HomePageHero;
