import React, { useState} from 'react';
import { RadioGroup } from '@headlessui/react';
import Button from '../../../Button';
import TextLink from '../../../TextLink';
import Typography from '../../../Typography';
import SelectOptionModalContainer from '../SelectOptionModalContainer';
import Loader from '../../../Loader';

import { FcDocument } from "react-icons/fc";

function SelectOptionModalContent({ options, 
    onClose,
    isOpen, 
    onSubmit, 
    isModalLoading,
 }) {
    const [currentStep, setCurrentStep] = useState(1);

    const [userChoices, setUserChoices] = useState(() =>
        options.reduce((acc, option) => {
            acc[option.optionName] = option.choices[0] || ""; // Utiliser le premier choix comme valeur par défaut
            return acc;
        }, {})
    );



    const totalSteps = options.length;

    const currentOption = options[currentStep - 1];

    const handleNext = () => {
        if (currentStep < totalSteps) setCurrentStep((prev) => prev + 1);
    };

    const handlePrev = () => {
        if (currentStep > 1) setCurrentStep((prev) => prev - 1);
    };

    const handleChoiceChange = (optionName, choice) => {
        setUserChoices((prev) => ({
            ...prev,
            [optionName]: choice,
        }));
    };

    const handleFinalSubmit = () => {
        onSubmit(userChoices);
    };

    return (
        <SelectOptionModalContainer title="Sélectionnez vos options" isOpen={isOpen} onClose={onClose}>
            {isModalLoading ? (
                <div className="flex flex-col justify-center items-center h-32">
                    <Loader size="lg" color="primary" />
                    <Typography variant="body-lg" className="mt-2">
                        Préparation de votre document...
                    </Typography>
                </div>
            ) : (
                <div className="flex flex-col w-full h-full">
                    {/* Header Navigation */}
                    <div className="mt-2 text-center border-b border-gray-300">
                        <Typography variant="lead" weight="bold" className="mb-4 text-center">
                            Selectionnez vos options
                        </Typography>
                        <div className="flex items-center justify-center space-x-4">
                            <button 
                                onClick={handlePrev} 
                                disabled={currentStep === 1}
                                className={`text-2xl ${currentStep === 1 ? 'text-gray-400 cursor-not-allowed' : ''}`}
                            >
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="size-6">
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M15.75 19.5 8.25 12l7.5-7.5" />
                                </svg>
                            </button>
                            <div className="flex space-x-2">
                                {options.map((option, index) => (
                                    <span
                                        key={index}
                                        onClick={() => setCurrentStep(index + 1)}
                                        className={`px-4 py-2 cursor-pointer ${
                                            currentStep === index + 1 ? 'font-bold text-primary-600' : 'text-gray-600'
                                        } text-sm`}
                                    >
                                        {option.optionName}
                                    </span>
                                ))}
                            </div>
                            <button 
                                onClick={handleNext} 
                                disabled={currentStep === totalSteps}
                                className={`text-2xl ${currentStep === totalSteps ? 'text-gray-500 cursor-not-allowed' : ''}`}
                            >
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="size-6">
                                    <path strokeLinecap="round" strokeLinejoin="round" d="m8.25 4.5 7.5 7.5-7.5 7.5" />
                                </svg>
                            </button>
                        </div>
                    </div>

                    {/* Content Section */}
                    <div className="mt-4 flex-grow">
                        <h4 className="text-lg font-medium mb-2">{currentOption.optionName}</h4>
                        <RadioGroup value={userChoices[currentOption.optionName]} onChange={(choice) => handleChoiceChange(currentOption.optionName, choice)}>
                            <RadioGroup.Label className="sr-only">Choisissez une option</RadioGroup.Label>
                            <div className="space-y-2">
                                {Array.from(new Set(currentOption.choices)).map((choice, index) => (
                                    <RadioGroup.Option
                                        key={index}
                                        value={choice}
                                        className={({ active, checked }) =>
                                            `relative rounded border p-4 cursor-pointer flex focus:outline-none 
                                            ${checked ? 'border-primary-400 bg-primary-200' : 'border-gray-100 bg-white'} 
                                            hover:bg-gray-50`
                                        }
                                    >
                                        {({ checked }) => (
                                            <div className="flex items-center justify-between w-full">
                                                <div className="text-sm">
                                                    <RadioGroup.Label as="p" className={`${checked ? 'text-primary-600' : 'text-gray-900'}`}>
                                                        {choice}
                                                    </RadioGroup.Label>
                                                </div>
                                                {checked && (
                                                    <div className="shrink-0 text-primary-600">
                                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" className="w-6 h-6">
                                                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 13l4 4L19 7" />
                                                        </svg>
                                                    </div>
                                                )}
                                            </div>
                                        )}
                                    </RadioGroup.Option>
                                ))}
                            </div>
                        </RadioGroup>
                    </div>

                    {/* Footer */}
                    <div className="flex justify-between items-center mt-4">
                        <TextLink variant="retour" size="md" onClick={onClose}>
                            Annuler
                        </TextLink>
                        {currentStep === totalSteps ? (
                            <Button variant="primary" size="sm" onClick={handleFinalSubmit}>
                                Valider
                            </Button>
                        ) : (
                            <Button variant="primary" size="sm" onClick={handleNext}>
                                Suivant
                            </Button>
                        )}
                    </div>
                </div>
            )}
        </SelectOptionModalContainer>
    );
}

export default SelectOptionModalContent;
