import React from 'react';
import { Button, Modal } from 'flowbite-react';
import { HiOutlineExclamationCircle } from 'react-icons/hi';
import PropTypes from 'prop-types';

function AlertModal({ isOpen, onClose, title, message, onConfirm, confirmText = "Yes", cancelText = "No" }) {
  return (
    <Modal show={isOpen} size="md" onClose={onClose} popup>
      <Modal.Header />
      <Modal.Body>
        <div className="relative h-full">
          {/* Partie sticky en haut avec l'icône et le titre */}
          <div className="sticky top-0 bg-white z-10">
            <div className="text-center">
              <HiOutlineExclamationCircle className="mx-auto mb-4 h-14 w-14 text-gray-400 dark:text-gray-200" />
              <h3 className="mb-4 text-lg font-normal text-gray-500 dark:text-gray-400">
                {title} {/* Utilisation de la prop `title` ici */}
              </h3>
            </div>
          </div>

          {/* Zone défilante pour le message ou la liste des champs */}
          <div className="max-h-60 overflow-y-auto text-left mb-4">
            <ul className="list-disc pl-5 space-y-1">
              {message}
            </ul>
          </div>

          {/* Boutons sticky en bas */}
          <div className="sticky bottom-0 bg-white py-2 flex justify-center gap-4">
            <Button color="failure" onClick={onClose}>
              {cancelText}
            </Button>
            <Button color="gray" onClick={onConfirm}>
              {confirmText}
            </Button>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
}

AlertModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  title: PropTypes.string,  // Autoriser le titre
  message: PropTypes.node.isRequired,  // Utiliser node pour autoriser le JSX dans le message
  onConfirm: PropTypes.func.isRequired,
  confirmText: PropTypes.string,
  cancelText: PropTypes.string,
};

export default AlertModal;
