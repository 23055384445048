import React, { useEffect} from 'react';
import { Text, TextArea, Select, Gender, Option, Logo } from '../PlaceholdersTypes';

function PlaceholdersGeneration({ 
    documentData, 
    userChoices, 
    userInputs, 
    setUserInputs 
}) {
    // Utiliser les placeholders de `option_metadata` s'ils existent, sinon ceux de `document_metadata`
    const data = documentData?.option_metadata?.placeholders?.length > 0
        ? documentData.option_metadata
        : documentData.document_metadata;

    // Initialiser tous les champs avec des valeurs vides lors du premier rendu une fois que les placeholders sont prêts
    useEffect(() => {
        if (data.placeholders && data.placeholders.length > 0) {
            const initialInputs = {};
            let shouldUpdate = false;

            data.placeholders.forEach(placeholder => {
                const { name } = placeholder; // Utilisation minimale, juste pour l'initialisation

                // Vérifier si le champ n'est pas déjà dans `userInputs`
                if (!(name in userInputs)) {
                    initialInputs[name] = '';  // Initialiser avec une chaîne vide
                    shouldUpdate = true;  // Marquer pour mise à jour
                }
            });

            if (shouldUpdate) {
                setUserInputs(prevInputs => ({ ...prevInputs, ...initialInputs }));
            }
        }
    }, [data.placeholders, userInputs, setUserInputs]);

    const handleInputChange = (name, value, label = "Normal") => { // Par défaut à "Normal"
        setUserInputs(prevInputs => ({
            ...prevInputs,
            [name]: { value, label }  // Stocker la valeur et le type (label) du champ
        }));
    };
    

    if (!data || !Array.isArray(data.placeholders)) {
        return <p>Aucun placeholder disponible</p>;
    }
    
    return (
        <div>
            {data.placeholders.map((placeholder, index) => {

                const label = placeholder.is_enterprise ? "Enterprise" : placeholder.is_employee ? "Employee" : "Normal";

                switch (placeholder.type) {
                    case 'text':
                        return (
                            <Text
                                key={index}
                                name={placeholder.name}
                                value={userInputs[placeholder.name]?.value || userChoices[placeholder.name] || ''}
                                onChange={(name, value) => handleInputChange(name, value, label)}
                                label={label}
                            />
                        );

                    case 'textarea':
                        return (
                            <TextArea
                                key={index}
                                name={placeholder.name}
                                value={userInputs[placeholder.name]?.value || userChoices[placeholder.name] || ''}
                                onChange={(name, value) => handleInputChange(name, value, "Normal")}
                                label={label}
                            />
                        );

                        case 'select':
                            return (
                                <Select
                                    key={index}
                                    name={placeholder.name}  // Utiliser le nom du placeholder ici
                                    choices={placeholder.data || []}
                                    value={userInputs[placeholder.name]?.value || userChoices[placeholder.name] || ''}
                                    onChange={(name, value) => handleInputChange(name, value, "Normal")}
                                />
                            );
                        
                        case 'gender':
                            return (
                                <Gender
                                    key={index}
                                    name={placeholder.name}  // Utilise le nom dynamique pour le genre, comme "gender_1"
                                    value={userInputs[placeholder.name]?.value || ''}
                                    onChange={(name, value) => handleInputChange(name, value, "Normal")}
                                />
                            );

                        case 'logo':
                            return (
                                <Logo
                                    key={index}
                                    name={placeholder.name}
                                    value={userInputs[placeholder.name]?.value || userChoices[placeholder.name] || {}}
                                    onChange={(name, value) => handleInputChange(name, value, "Logo")}
                                />
                            );

                        
                        case 'option':
                            return (
                                <Option
                                    key={index}
                                    name={placeholder.optionName || 'Option'}
                                    placeholders={placeholder.placeholders || []}
                                    userInputs={userInputs} // Passe userInputs pour chaque niveau d'option
                                    onChange={(name, value) => handleInputChange(name, value)}
                                />
                            );

                    default:
                        return null;
                }
            })}
        </div>
    );
}

export default PlaceholdersGeneration;
