import React from 'react';
import TextLink from '../../../../style/TextLink';
import ButtonDropdown from '../../../../style/ButtonDropdown';

function PlaceholdersFooter({ onGenerate, onChangeModel }) {
    return (
        <div className="sticky bottom-0 w-full p-4 border-t border-gray-300 z-10 flex justify-between items-center px-10">
            {/* Bouton "Retour" à gauche */}
            <TextLink variant="retour" size="md" onClick={onChangeModel}>
                Retour
            </TextLink>

            {/* Bouton Dropdown à droite */}
            <ButtonDropdown
                onGenerate={(selectedOptions) => {
                    const isWordSelected = !!selectedOptions.word;  // Conversion en booléen
                    const isPDFSelected = !!selectedOptions.pdf;    // Conversion en booléen

                    // Appeler la fonction onGenerate avec les deux valeurs booléennes
                    if (onGenerate) {
                        onGenerate(isWordSelected, isPDFSelected);
                    }
                }}
            />


        </div>
    );
}

export default PlaceholdersFooter;
