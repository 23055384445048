import React from 'react';
import Typography from '../../../../../style/Typography';

function Gender({ name, value, onChange }) {
    const genderOptions = ["Féminin", "Masculin"];

    // Gère le changement de genre (Féminin ou Masculin)
    const handleGenderChange = (event) => {
        const selectedOption = event.target.value;  // Récupère la valeur sélectionnée
        onChange(name, selectedOption);  // Appelle la fonction de mise à jour avec le nom du champ et la valeur
    };

    return (
        <div className="mb-4 w-full flex flex-col items-center">
            <div className="flex items-center w-[70%] space-x-4">
                {/* Label principal avec Typography */}
            <Typography
                variant="body-base"
                theme="gray-700"
                className="whitespace-nowrap"
            >
                Genre :
            </Typography>

                {genderOptions.map((option, index) => (
                    <label key={index} className="flex items-center space-x-2 cursor-pointer">
                        <input
                            type="radio"
                            name={name}
                            value={option}
                            checked={value === option}
                            onChange={handleGenderChange}
                            className="cursor-pointer"
                        />
                        <Typography
                            variant="body-base"
                            theme={value === option ? 'primary' : 'gray-600'}
                            className={value === option ? 'font-semibold' : ''}
                        >
                            {option}
                        </Typography>
                    </label>
                ))}
            </div>
        </div>
    );
}

export default Gender;
