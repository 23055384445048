import React, { useState, memo } from 'react';
import ModelPreview from '../DocumentGenerationUtilities/ModelPreview';
import PlaceholdersGeneration from '../DocumentGenerationUtilities/PlaceholdersGeneration';
import PlaceholdersHeader from '../DocumentGenerationUtilities/PlacesholdersHeader';
import PlaceholdersFooter from '../DocumentGenerationUtilities/PlaceholdersFooter';
import AlertModal from '../../../style/Modals/AlertModal';
import GenerateDocumentOkModal from '../../../style/Modals/GenerateDocumentOkModal';
import { extractAndReplacePlaceholders, uploadAndProcessLogo } from '../../../../utils/apiUtilities';


function DocumentGenerator({ documentData, onBack, userChoices }) {
    const [choices, setChoices] = useState({});
    const [userInputs, setUserInputs] = useState({});
    const [isGenerateDocumentOkModalOpen, setIsGenerateDocumentOkModalOpen] = useState(false); // Camel case correct
    const [isAlertOpen, setIsAlertOpen] = useState(false);
    const [missingFields, setMissingFields] = useState([]); // Champs manquants pour l'alerte

    // Nouvel état pour stocker temporairement les options Word et PDF
    const [generateOptions, setGenerateOptions] = useState({ isWord: false, isPDF: false });

    const checkMissingFields = () => {
        const missing = [];

        // Vérifier que `option_metadata.placeholders` existe et est un tableau
        if (!documentData.option_metadata || !Array.isArray(documentData.option_metadata.placeholders)) {
            console.warn("Aucun placeholder trouvé dans option_metadata");
            return missing; // Retourne un tableau vide si les placeholders sont absents
        }
    
        // Parcourir chaque placeholder pour vérifier les champs manquants
        documentData.option_metadata.placeholders.forEach(placeholder => {
            const placeholderName = placeholder.name;
            const input = userInputs[placeholderName];
            const value = typeof input === 'object' ? input?.value : input;  // Récupère `value` si `input` est un objet
    
            // Gestion des champs de type "gender"
            if (placeholder.type === 'gender') {
                if (!value || (typeof value === 'string' && value.trim() === '')) {
                    missing.push(`Genre: ${placeholder.data.join(' / ')}`);
                }
            }
            // Gestion des champs de type "select"
            else if (placeholder.type === 'select') {
                if (!value || (typeof value === 'string' && value.trim() === '')) {
                    missing.push(`Select: ${placeholder.data.join(', ')}`);
                }
            }
            // Gestion des autres types de champs (text, textarea, etc.)
            else if (!value || (typeof value === 'string' && value.trim() === '')) {
                missing.push(placeholderName);  // Ajouter le nom du champ manquant
            }
        });
    
        return missing; // Retourner la liste des champs manquants
    };
    
    

    
    const handleGenerate = (isWord, isPDF) => {
        // Obtenir la liste des champs manquants
        const missing = checkMissingFields();

        // Si des champs sont manquants, ouvrir la modale d'alerte
        if (missing.length > 0) {
            setMissingFields(missing);
            setGenerateOptions({ isWord, isPDF });  // Enregistrer les options sélectionnées
            setIsAlertOpen(true);  // Ouvrir la modale d'alerte
        } else {
            // Tout est rempli, passer directement à la génération du document
            handleConfirmText(isWord, isPDF);
        }
    };

    const handleLogoUpload = async () => {
        // Vérifiez si le champ 'Logo' existe et contient un fichier à uploader
        const logoInput = userInputs?.Logo?.value;
    
        if (logoInput?.fileContent) {
            console.log("Traitement du logo en cours...");
    
            try {
                // Appel au backend pour uploader et traiter le logo
                const { url } = await uploadAndProcessLogo(logoInput.fileContent, logoInput.fileName);
    
                // Mise à jour de la valeur du champ 'Logo' avec l'URL renvoyée par le backend
                setUserInputs((prevInputs) => ({
                    ...prevInputs,
                    Logo: { ...prevInputs.Logo, value: url },
                }));
    
                console.log("Logo traité avec succès, URL:", url);
            } catch (error) {
                console.error("Erreur lors du traitement du logo:", error);
                throw new Error("Échec de l'upload du logo");
            }
        }
    };
    
    
    const handleConfirmText = async (isWord, isPDF) => {
        console.log("Document generator user input", userInputs)

        try {
            // Étape 1 : Vérifier si un logo est présent
            if (userInputs.Logo && userInputs.Logo.value?.fileContent) {
                console.log("Traitement du logo...");
                const { url } = await uploadAndProcessLogo(
                    userInputs.Logo.value.fileContent,
                    userInputs.Logo.value.fileName
                );
                setUserInputs((prevInputs) => ({
                    ...prevInputs,
                    Logo: { ...prevInputs.Logo, value: url },
                }));
                console.log("Logo traité avec succès, URL:", url);
            }

            // Appel à l'API pour extraire et remplacer les placeholders dans le document généré
            const responseData = await extractAndReplacePlaceholders(userInputs, isWord, isPDF, documentData);  // Envoyer tout documentData
            
            const files = responseData.files;
            // Appeler la fonction pour télécharger automatiquement les fichiers
            downloadFiles(files);
    
            setIsGenerateDocumentOkModalOpen(false); // Fermer la modale après confirmation
            setIsAlertOpen(false);  // Fermer l'alerte si ouverte
    
        } catch (error) {
            console.error('Erreur lors de l\'appel de l\'API:', error);
        } 
    };
    
    
    const downloadFiles = async (files) => {
        console.log("Files retournés par l'API:", files);

        if (files.word_url) {
            await downloadFile(files.word_url, "document_word.docx");
            await delay(500); // Délai de 500 ms entre les téléchargements
        } else {
            console.warn("URL pour le fichier Word non disponible.");
        }
    
        if (files.pdf_url) {
            await downloadFile(files.pdf_url, "document_pdf.pdf");
        } else {
            console.warn("URL pour le fichier PDF non disponible.");
        }
    };
    
    // Fonction utilitaire pour télécharger un fichier
    const downloadFile = async (url, filename) => {
        return new Promise((resolve) => {
            const link = document.createElement("a");
            link.href = url;
            link.download = filename;
            document.body.appendChild(link);
    
            // Utiliser `setTimeout` pour s'assurer que le clic est bien traité
            setTimeout(() => {
                link.click();
                document.body.removeChild(link);
                resolve();
            }, 100);
        });
    };
    
    // Fonction utilitaire pour ajouter un délai
    const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));
    
    // Fonction pour réinitialiser les entrées utilisateur
    const resetUserInputs = () => {
        setUserInputs({});
    };

    // Fonctions d’action pour les boutons
    const handlePreview = () => {
        console.log('Prévisualiser le document');
    };

    const handleChangeModel = () => {
        console.log('Changer de modèle');
        onBack();
    };

    return (
        <div className="w-full mx-auto flex flex-col h-full">
            <div className="flex items-center justify-center w-full h-full space-x-2">
                <div className='flex flex-1 h-full items-center justify-center rounded'>
                 <ModelPreview documentData={documentData} />
                </div>

                <div className='flex flex-1 flex-col h-full items-center justify-center border border-gray-200 rounded bg-white'> 
                    <div className="flex-shrink-0 w-full rounded-t">
                        <PlaceholdersHeader documentData={documentData} />
                    </div>
    
                    {/* Section avec les placeholders, avec scroll indépendant */}
                    <div className="w-full overflow-y-auto">
                        <PlaceholdersGeneration 
                            documentData={documentData} 
                            userChoices={choices} 
                            setChoices={setChoices}
                            userInputs={userInputs}
                            setUserInputs={setUserInputs}
                        />
                    </div>
    
                    {/* Footer toujours en bas */}
                    <div className="flex-shrink-0 w-full rounded-b bg-gray-100">
                        <PlaceholdersFooter 
                            onPreview={handlePreview}
                            onGenerate={handleGenerate}
                            onChangeModel={handleChangeModel}
                        />
                    </div>
                </div>
            </div>
            
        
    
            {/* Modales */}
            <GenerateDocumentOkModal
                isOpen={isGenerateDocumentOkModalOpen}
                onClose={() => setIsGenerateDocumentOkModalOpen(false)}
                title="Documents générés"
                bodyContent={<p>Les documents ont été générés avec succès !</p>}
                onConfirm={() => {
                    console.log('Génération confirmée');
                }}
                onReset={() => {
                    resetUserInputs();
                    setIsGenerateDocumentOkModalOpen(false);
                }}
                onGoBack={() => {
                    onBack();
                }}
                confirmText="Recommencer"
                cancelText="Retour au menu"
            />
    
            <AlertModal
                isOpen={isAlertOpen}
                onClose={() => setIsAlertOpen(false)}
                title="Les champs suivants n'ont pas été remplis, êtes-vous sûr de vouloir continuer ?"
                message={missingFields.map((field, index) => (
                    <li key={index}>{field}</li>
                ))}
                onConfirm={() => handleConfirmText(generateOptions.isWord, generateOptions.isPDF)}
                confirmText="Confirmer"
                cancelText="Annuler"
            />
        </div>
    );
    
}    

export default memo(DocumentGenerator);
