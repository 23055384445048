import React from 'react';
import TutorialComponent from '../TutorialComponent';

function TutorialContainer() {
    return (
        <div>
            <h2 className="text-2xl font-bold mb-4">Tutoriel</h2>
            {/* Ajouter chaque tutoriel ici */}
            <TutorialComponent title="Comment ajouter une variable" description="Description pour ajouter une variable" />
            <TutorialComponent title="Comment gérer les modèles" description="Description pour gérer les modèles" />
        </div>
    );
}

export default TutorialContainer;
