import React, { useState } from 'react';

function ModelPreview({ documentData }) {
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(false);

    const getDocumentPdfUrl = (documentData) => {
        const baseUrl = process.env.REACT_APP_API_URL;
        if (!documentData) return null;

        const cleanUrl = (url) => url.trim();

        if (documentData?.document_metadata?.options?.length > 0) {
            if (documentData?.pdf_url_temp) return `${baseUrl}${cleanUrl(documentData.pdf_url_temp)}`;
            if (documentData?.mongo_id) return `${baseUrl}/mongo/${cleanUrl(documentData.mongo_id)}/pdf_temp`;
        } else {
            if (documentData?.pdf_url) return `${baseUrl}${cleanUrl(documentData.pdf_url)}`;
            if (documentData?.mongo_id) return `${baseUrl}/mongo/${cleanUrl(documentData.mongo_id)}/pdf`;
        }
        return null;
    };

    const documentPdfUrl = getDocumentPdfUrl(documentData);

    return (
        <div className="flex items-center justify-center w-full h-full bg-transparent overflow-hidden">
            {documentPdfUrl ? (
                <>
                    {loading && <p className="text-gray-500">Chargement...</p>}
                    <iframe
                        src={documentPdfUrl} 
                        title="Aperçu du document utilisateur"
                        className="h-full w-full rounded border-none"
                        onLoad={() => setLoading(false)}
                        onError={() => {
                            setLoading(false);
                            setError(true);
                        }}
                    />
                </>
            ) : error ? (
                <p className="text-red-500">Impossible de charger le document PDF.</p>
            ) : (
                <p>Aucun aperçu disponible</p>
            )}
        </div>
    );
}

export default ModelPreview;
