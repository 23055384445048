import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Typography from '../Typography';

function TableDocument({ columns, data, onRowClick }) {
    const [selectedRow, setSelectedRow] = useState(null);

    const handleRowClick = (row) => {
        setSelectedRow(row);
        if (onRowClick) {
            onRowClick(row);
        }
    };

    return (
        <div className="overflow-auto">
            <table className="w-full table-auto border-collapse">
                {/* En-tête du tableau */}
                <thead>
                    <tr className="bg-gray-200">
                        {columns.map((column, index) => (
                            <th
                                key={index}
                                className={`px-4 py-3 text-left ${
                                    column.width ? `w-${column.width}` : ''
                                } truncate whitespace-nowrap overflow-hidden`}
                                title={column.label}
                            >
                                <Typography
                                    variant="body-base"
                                    weight="bold"
                                    className="truncate whitespace-nowrap overflow-hidden"
                                >
                                    {column.label}
                                </Typography>
                            </th>
                        ))}
                    </tr>
                </thead>

                {/* Corps du tableau */}
                <tbody>
                    {data.map((row, rowIndex) => (
                        <tr
                            key={rowIndex}
                            className={`border-t text-center cursor-pointer transition-all duration-150 ${
                                selectedRow === row
                                    ? 'bg-blue-100 scale-105 shadow-md'
                                    : 'hover:bg-gray-100'
                            }`}
                            onClick={() => handleRowClick(row)}
                        >
                            {columns.map((column, colIndex) => (
                                <td
                                    key={colIndex}
                                    className="px-4 py-3 truncate text-base whitespace-nowrap overflow-hidden"
                                >
                                    {column.type === 'icon' && column.render ? (
                                        column.render(row)
                                    ) : (
                                        <Typography variant="body-sm">
                                            {column.render
                                                ? column.render(row[column.key], row)
                                                : row[column.key]}
                                        </Typography>
                                    )}
                                </td>
                            ))}
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
}

TableDocument.propTypes = {
    columns: PropTypes.arrayOf(
        PropTypes.shape({
            label: PropTypes.string.isRequired, // Nom d'affichage de la colonne
            key: PropTypes.string, // Clé des données pour cette colonne
            width: PropTypes.string, // Largeur de la colonne (optionnelle)
            type: PropTypes.oneOf(['text', 'icon']), // Type de colonne : text ou icon (par défaut text)
            render: PropTypes.func, // Fonction de rendu personnalisé (optionnelle)
        })
    ).isRequired,
    data: PropTypes.arrayOf(PropTypes.object).isRequired, // Données à afficher
    onRowClick: PropTypes.func, // Fonction appelée lors du clic sur une ligne
};

export default TableDocument;
