import React from 'react';
import PropTypes from 'prop-types';

function TextLink({ variant = 'classique', size = 'md', children, onClick, customClass }) {
    let variantClass = '';
    switch (variant) {
        case 'retour':
            variantClass = 'text-gray-500 hover:text-gray-800';
            break;
        case 'validation':
            variantClass = 'text-primary-500 hover:text-primary-600';
            break;
        case 'danger':
            variantClass = 'text-red-600 hover:text-red-800';
            break;
        case 'classique':
        default:
            variantClass = 'text-gray-600 hover:text-primary';
    }

    let sizeClass = '';
    switch (size) {
        case 'sm':
            sizeClass = 'text-sm';
            break;
        case 'md':
            sizeClass = 'text-md';
            break;
        case 'lg':
            sizeClass = 'text-lg';
            break;
        default:
            sizeClass = 'text-md';
    }

    const textLinkClasses = `cursor-pointer ${variantClass} ${sizeClass} ${customClass}`;

    return (
        <div onClick={onClick} className={textLinkClasses}>
            {children}
        </div>
    );
}

TextLink.propTypes = {
    variant: PropTypes.oneOf(['retour', 'classique', 'validation', 'danger']),
    size: PropTypes.oneOf(['sm', 'md', 'lg']),
    children: PropTypes.node.isRequired,
    onClick: PropTypes.func,
    customClass: PropTypes.string,
};

export default TextLink;
