import React from 'react';
import Button from '../../components/style/Button';
import Typography from '../../components/style/Typography';
import { GlobalContainer } from '../../components/Containers/GlobalContainer';
import { Img } from "react-image";
import { useNavigate } from 'react-router-dom';


// Données pour les éléments de la liste
const features = [
    {
        title: "Une bibliothèque prête à l'emploi",
        description: "Accédez à une collection de modèles conçus pour répondre aux besoins RH les plus fréquents. Plus besoin de repartir de zéro !"
    },
    {
        title: "Une personnalisation sans effort",
        description: "Ajustez vos documents en quelques clics, avec une interface intuitive qui s'adapte à vos exigences."
    },
    {
        title: "Un système d’options intelligent",
        description: "Dites adieu aux doublons inutiles. Créez des modèles dynamiques qui s'adaptent à chaque situation, pour une gestion encore plus fluide."
    }
];

// Données pour les bénéfices
const benefits = [
    {
        title: "Gagnez un temps précieux",
        description: "Automatisez la création de vos documents et concentrez-vous sur des tâches plus importantes. Plus besoin de copier-coller ou de jongler avec des modèles Word."
    },
    {
        title: "Réduisez le stress et les erreurs",
        description: "Avec des modèles standardisés et dynamiques, minimisez les risques d’erreurs et déléguez en toute sérénité."
    },
    {
        title: "Retrouvez l’équilibre vie pro/perso",
        description: "Libérez-vous des tâches chronophages et retrouvez du temps pour vous concentrer sur l'humain et votre bien-être."
    }
];


function LearnMore() {
    const navigate = useNavigate();

    
    return (
        <section className="bg-gray-50 py-16">
            <GlobalContainer className="flex flex-col space-y-12">
                {/* Titre principal */}
                <div className="text-center">
                    <Typography variant="h1" weight="bold" className="text-primary-600">
                        En savoir plus sur Bara Doc
                    </Typography>
                    <Typography variant="body-lg" theme="gray-700" className="mt-4">
                        Simplifiez la rédaction de vos documents RH avec un outil conçu pour les RH par des RH.
                    </Typography>
                </div>

                {/* Introduction */}
                <div className="bg-white shadow-md rounded-lg p-8">
                    <Typography variant="h2" weight="bold" className="text-primary-600 mb-4">
                        Pourquoi Bara Doc ?
                    </Typography>
                    <Typography variant="body-base" theme="gray-600" className="leading-relaxed">
                        Après avoir passé des heures à copier-coller des modèles Word, j’ai réalisé qu’il devait exister une meilleure solution.
                        C’est là qu’est née l’idée de Bara Doc : simplifier ces tâches répétitives et chronophages une bonne fois pour toutes.
                    </Typography>
                    <Typography variant="body-base" theme="gray-600" className="leading-relaxed mt-4">
                        Ce SaaS est conçu pour les RH, par des RH, en répondant précisément aux besoins du métier. Avec Bara Doc, libérez du temps
                        pour vous concentrer sur vos activités à plus forte valeur ajoutée.
                    </Typography>
                </div>

                {/* Bénéfices principaux */}
                <div className="bg-white shadow-md rounded-lg p-8">
                    <Typography variant="h2" weight="bold" className="text-primary-600 mb-4">
                        Les avantages pour vous
                    </Typography>
                    <div className="space-y-8">
                        {benefits.map((benefit, index) => (
                            <div key={index} className="text-center">
                                <Typography variant="body-lg" weight="bold" className="text-gray-800">
                                    {benefit.title}
                                </Typography>
                                <Typography variant="body-base" theme="gray-600" className="mt-2">
                                    {benefit.description}
                                </Typography>
                            </div>
                        ))}
                    </div>
                </div>

                {/* Ce que propose Bara Doc */}
                <div className="bg-white shadow-md rounded-lg p-8">
                    <Typography variant="h2" weight="bold" className="text-primary-600 mb-4">
                        Ce que nous proposons :
                    </Typography>
                    <ul className="space-y-6">
                        {features.map((feature, index) => (
                            <li key={index} className="text-center">
                                <Typography variant="body-lg" weight="bold" className="text-gray-800">
                                    {feature.title} :
                                </Typography>
                                <Typography variant="body-base" theme="gray-600" className="mt-2">
                                    {feature.description}
                                </Typography>
                            </li>
                        ))}
                    </ul>
                </div>

                {/* Qui sommes-nous */}
                <div className="bg-white shadow-md rounded-lg p-8">
                    <Typography variant="h2" weight="bold" className="text-primary-600 mb-4">
                        Qui sommes-nous ?
                    </Typography>
                    <Typography variant="body-base" theme="gray-600" className="leading-relaxed">
                        Bara Doc, c’est avant tout une aventure familiale. Je suis freelance RH et développeur web autodidacte, et ma femme est RH et comptable expérimentée. Ensemble, nous avons mis nos années d’expérience au service d’une idée simple : créer un outil qui répond aux véritables besoins des professionnels RH. <br/>
                        Nous comprenons les défis que vous affrontez, parce que nous les avons vécus nous-mêmes. Bara Doc est le fruit de cette expertise, et chaque fonctionnalité a été pensée avec une seule idée en tête : vous simplifier la vie.
                    </Typography>
                </div>

                {/* Conclusion */}
                <div className="bg-primary-100 text-primary-800 p-8 rounded-lg text-center">
                    <Typography variant="h2" weight="bold" className="mb-4">
                        Une solution conçue pour les professionnels RH
                    </Typography>
                    <Typography variant="body-lg" className="leading-relaxed">
                        Notre objectif est simple : vous offrir un outil qui simplifie vos processus, vous libère du stress et vous redonne du temps.
                        Rejoignez Bara Doc et révolutionnez votre façon de créer vos documents !
                    </Typography>
                    <div className="flex justify-center mt-6">
                        <Button 
                            variant="primary" 
                            size="md"
                            className="transform hover:scale-105 transition-transform duration-300"
                            onClick={() => navigate('/document-manager')}
                        >
                            Créer mon premier document
                        </Button>
                    </div>
                </div>
            </GlobalContainer>
        </section>
    );
}

export default LearnMore;
