import React from 'react';

function AdminUsersTable({ users, onEdit, onDelete }) {
    return (
        <div>
            <h2 className="text-2xl font-bold mb-4">Utilisateurs</h2>
            <table className="min-w-full bg-white shadow-md rounded overflow-hidden">
                <thead>
                    <tr>
                        <th className="py-2 px-4 border-b">Nom</th>
                        <th className="py-2 px-4 border-b">Email</th>
                        <th className="py-2 px-4 border-b">Mot de passe</th>
                        <th className="py-2 px-4 border-b">Rôle</th>
                        <th className="py-2 px-4 border-b">Documents ajoutés</th>
                        <th className="py-2 px-4 border-b">Date de création</th>
                        <th className="py-2 px-4 border-b">Dernière connexion</th>
                        <th className="py-2 px-4 border-b">Actions</th>
                    </tr>
                </thead>
                <tbody>
                    {users.map((user) => (
                        <tr key={user.id} className="hover:bg-gray-100">
                            <td className="py-2 px-4 border-b">{user.username}</td>  {/* Assurez-vous que le champ est bien `username` */}
                            <td className="py-2 px-4 border-b">{user.mail}</td>      {/* Assurez-vous que le champ est bien `mail` */}
                            <td className="py-2 px-4 border-b">****</td>  {/* Ne pas afficher le mot de passe */}
                            <td className="py-2 px-4 border-b">{user.global_role}</td> {/* Utilisez `global_role` si c’est le nom correct */}
                            <td className="py-2 px-4 border-b">{user.documentCount || 0}</td>
                            <td className="py-2 px-4 border-b">{new Date(user.created_at).toLocaleString()}</td> {/* Formatez la date */}
                            <td className="py-2 px-4 border-b">{user.lastLogin || 'Jamais connecté'}</td>
                            <td className="py-2 px-4 border-b">
                                <button onClick={() => onEdit(user)} className="text-blue-500 mr-2">Modifier</button>
                                <button onClick={() => onDelete(user)} className="text-red-500">Supprimer</button>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
}

export default AdminUsersTable;
