import React from 'react';

function FeaturesToAdd() {
    return (
        <div className="container mx-auto px-6 py-12 w-full">
            <h2 className="text-3xl font-bold mb-6">Fonctionnalités à Ajouter</h2>
            <div>
                <h3 className="text-xl font-semibold mb-4">Flux d'Ajout et de Traitement du Document</h3>
                <ol className="list-decimal list-inside ml-4 space-y-4">
                    <li>
                        <strong>Ajout du document</strong> : L'utilisateur télécharge le document via le formulaire d'upload.
                    </li>

                    <li>
                        <strong>Traitement initial</strong> : Un bref chargement s'effectue pendant que le document passe par deux étapes :
                        <ul className="list-disc list-inside ml-6 space-y-2">
                            <li>API <code>validate_document</code> : Vérification des erreurs de syntaxe.</li>
                            <li>API <code>extract_document</code> : Récupération de toutes les données du document.</li>
                        </ul>
                    </li>

                    <li>
                        <strong>Affichage des options</strong> : Toutes les options et les champs sont maintenant affichés pour permettre à l'utilisateur de personnaliser le document.
                    </li>

                    <li>
                        <strong>Sélection des options</strong> : L'utilisateur choisit les options, puis clique sur "Valider".
                    </li>

                    <li>
                        <strong>Application des choix</strong> :
                        <ul className="list-disc list-inside ml-6 space-y-2">
                            <li>API <code>choice_user</code> : Récupère les informations extraites et associe les choix de l'utilisateur.</li>
                            <li>API <code>send_document_user_choice</code> : Envoie les données finales selon les choix de l'utilisateur pour la génération du document.</li>
                        </ul>
                    </li>

                    <li>
                        <strong>Gestion des documents déjà enregistrés</strong> : Si le document est déjà enregistré, seules les étapes suivantes sont nécessaires :
                        <ul className="list-disc list-inside ml-6 space-y-2">
                            <li>API <code>choice_user</code> : Pour adapter et récupérer les choix effectués.</li>
                            <li>API <code>send_document_user_choice</code> : Pour envoyer les choix finaux si des modifications sont nécessaires.</li>
                        </ul>
                    </li>
                </ol>
            </div>
            <div className="mb-8">
                <h3 className="text-xl font-semibold mb-2">Pense bête</h3>
                <ul>
                    <li><input type="checkbox" /> Mettre à jour la recherche dans 'Home'</li>
                    <li><input type="checkbox" /> Ajouter le système de catégorie et sous catégorie</li>
                    <li><input type="checkbox" /> Ajouter l'ajout de document avec gestion d'erreur</li>
                    <li><input type="checkbox" /> Traiter la génération multiple</li>
                    <li><input type="checkbox" /> Gestion d'erreur de bout en bout Home</li>
                    <li><input type="checkbox" /> Créer le serveur et faire tourner le backend</li>
                    <li><input type="checkbox" /> Créer la base de donnée POSTGRES temporaire</li>
                    <li><input type="checkbox" /> Ajouter de la récperation de data global et par user</li>
                    <li><input type="checkbox" /> Checker HOTJAR</li>
                    <li><input type="checkbox" /> Trouver un nom de domaine</li>
                    <li><input type="checkbox" /> Créer une authentification temporaire</li>
                    <li><input type="checkbox" /> Trouver un moyen de récuperer les data</li>
                </ul>
            </div>

            <div className="mb-8">
                <h3 className="text-xl font-semibold mb-2">1. 🚀 Authentification des utilisateurs</h3>
                <p><strong>Quand l'utiliser ?</strong> : Pour permettre aux utilisateurs de se connecter et d'accéder à leurs documents.</p>
                <p><strong>Comment l'écrire ?</strong> : Créer des formulaires d'inscription et de connexion.</p>
                <p><strong>Fonctionnement :</strong> Les utilisateurs peuvent s'inscrire et se connecter pour gérer leurs documents.</p>
            </div>

            <div className="mb-8">
                <h3 className="text-xl font-semibold mb-2">2. 📊 Tableau de bord utilisateur</h3>
                <p><strong>Quand l'utiliser ?</strong> : Pour afficher tous les documents d'un utilisateur dans un seul endroit.</p>
                <p><strong>Comment l'écrire ?</strong> : Développer une interface utilisateur pour afficher les documents.</p>
                <p><strong>Fonctionnement :</strong> Les utilisateurs peuvent voir, trier et gérer leurs documents facilement.</p>
            </div>

            <div className="mb-8">
                <h3 className="text-xl font-semibold mb-2">3. 📝 Prévisualisation du document</h3>
                <p><strong>Quand l'utiliser ?</strong> : Avant de finaliser un document pour vérifier son contenu.</p>
                <p><strong>Comment l'écrire ?</strong> : Intégrer une fonctionnalité de prévisualisation dans l'interface utilisateur.</p>
                <p><strong>Fonctionnement :</strong> Les utilisateurs peuvent voir à quoi ressemblera le document généré avant de le télécharger.</p>
            </div>

            <div className="mb-8">
                <h3 className="text-xl font-semibold mb-2">4. 🌐 Support pour plusieurs formats de documents</h3>
                <p><strong>Quand l'utiliser ?</strong> : Lors de la génération de documents dans différents formats comme PDF ou DOCX.</p>
                <p><strong>Comment l'écrire ?</strong> : Ajouter des options de format lors de la génération du document.</p>
                <p><strong>Fonctionnement :</strong> Les utilisateurs peuvent choisir le format de téléchargement souhaité.</p>
            </div>

            <div className="mb-8">
                <h3 className="text-xl font-semibold mb-2">5. 🎨 Amélioration de l'interface utilisateur</h3>
                <p><strong>Quand l'utiliser ?</strong> : Pour améliorer l'expérience utilisateur globale de l'application.</p>
                <p><strong>Comment l'écrire ?</strong> : Mettre à jour les styles CSS et la disposition des composants.</p>
                <p><strong>Fonctionnement :</strong> Rendre l'application plus agréable à utiliser et intuitive pour les utilisateurs.</p>
            </div>
        </div>
    );
}

export default FeaturesToAdd;
