import React, { useState, useRef, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { FiBell, FiHelpCircle, FiChevronDown, FiUser, FiMenu } from 'react-icons/fi';
import { useModal } from '../../context/Login/ModalContext';
import { useUser } from '../../context/User/UserContext';
import Button from '../style/Button';
import Typography from '../style/Typography';
import logo from '../../assets/logo/logo_temp.png';
import { GlobalContainer } from '../Containers/GlobalContainer';

function Header() {
    const { user, logout } = useUser();
    const { openModal } = useModal();
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const dropdownRef = useRef(null);

    const toggleDropdown = () => setIsDropdownOpen(!isDropdownOpen);
    const toggleMenu = () => setIsMenuOpen(!isMenuOpen);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                setIsDropdownOpen(false);
            }
        };
        document.addEventListener('mousedown', handleClickOutside);
        return () => document.removeEventListener('mousedown', handleClickOutside);
    }, []);

    const handleLogout = () => {
        logout();
    };

    return (
        <header className="sticky top-0 py-4 bg-white z-20 shadow-md">
            <GlobalContainer className="flex justify-between items-center px-10 lg:px-8">
                {/* Logo Section */}
                <div className="flex items-center space-x-3">
                    <Link to="/" className="flex items-center space-x-2">
                        <img src={logo} alt="Bara Doc Logo" className="h-8 w-8 sm:h-10 sm:w-10" />
                        <Typography 
                            variant="h5" 
                            className="hidden sm:block text-primary"
                            weight='bold'
                        >
                            Bara Doc
                        </Typography>
                    </Link>
                </div>


                {/* Mobile Navigation (Burger + Connexion) */}
                <div className="sm:hidden flex items-center space-x-3">
                    {!user && (
                        <Button size="sm" onClick={openModal}>
                            Connexion
                        </Button>
                    )}
                    <button onClick={toggleMenu} className="text-gray-700 focus:outline-none">
                        <FiMenu size={24} />
                    </button>
                </div>

                {/* Desktop Navigation */}
                <div className="hidden sm:flex items-center space-x-6">
                    <Typography variant="body-lg" className="hover:text-primary">
                        <Link to="/document-manager">Bibliothèque</Link>
                    </Typography>
                    <Typography variant="body-lg" className="hover:text-primary">
                        <Link to="/how-it-works">Guide</Link>
                    </Typography>
                    {user ? (
                        <div className="flex items-center space-x-4">
                            <Button variant="ico" icon={FiBell} iconTheme='white' />
                            <Button variant="ico" icon={FiHelpCircle} iconTheme='white'/>
                            <div className="relative" ref={dropdownRef}>
                                <button
                                    onClick={toggleDropdown}
                                    className="flex items-center space-x-2 text-gray-600 hover:text-primary focus:outline-none"
                                >
                                    {user.avatar ? (
                                        <img
                                            src={user.avatar}
                                            alt="avatar"
                                            className="w-8 h-8 rounded-full"
                                        />
                                    ) : (
                                        <FiUser size={24} className="w-8 h-8 rounded-full text-gray-500" />
                                    )}
                                    <FiChevronDown size={20} />
                                </button>

                                {isDropdownOpen && (
                                    <div className="absolute right-0 mt-2 w-48 bg-white rounded shadow-md border border-gray-200 z-20">
                                        <div className="px-4 py-3 border-b border-gray-200">
                                            <Typography variant="body-sm" weight="semibold">
                                                {user?.name}
                                            </Typography>
                                            <Typography variant="caption3" theme="gray">
                                                {user?.email}
                                            </Typography>
                                        </div>
                                        <Link
                                            to="/settings"
                                            className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                                        >
                                            Paramètres
                                        </Link>
                                        <Link
                                            to="/earnings"
                                            className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                                        >
                                            Abonnement
                                        </Link>
                                        <button
                                            onClick={handleLogout}
                                            className="w-full text-left px-4 py-2 text-sm text-red-700 hover:bg-gray-100 border-t"
                                        >
                                            Déconnexion
                                        </button>
                                    </div>
                                )}
                            </div>
                        </div>
                    ) : (
                        <Button size="sm" onClick={openModal}>
                            Connexion
                        </Button>
                    )}
                </div>
            </GlobalContainer>

            {/* Mobile Menu */}
            {isMenuOpen && (
                <div className="sm:hidden bg-white border-t border-gray-200 shadow-md">
                    <Typography variant="body-lg" className="block px-4 py-2 hover:bg-gray-100">
                        <Link to="/document-manager">Bibliothèque</Link>
                    </Typography>
                    <Typography variant="body-lg" className="block px-4 py-2 hover:bg-gray-100">
                        <Link to="/how-it-works">Guide</Link>
                    </Typography>
                    {user && (
                        <>
                            <Button
                                variant="icon"
                                icon={FiBell}
                                size={20}
                                className="hover:bg-gray-100 w-full text-left"
                            />
                            <Button
                                variant="icon"
                                icon={FiHelpCircle}
                                size={20}
                                className="hover:bg-gray-100 w-full text-left"
                            />
                            <button
                                onClick={handleLogout}
                                className="block w-full text-left px-4 py-2 text-sm text-red-700 hover:bg-gray-100"
                            >
                                Déconnexion
                            </button>
                        </>
                    )}
                </div>
            )}
        </header>
    );
}

export default Header;
