import React, { useState, useEffect } from 'react';
import Loader from '../../style/Loader';
import { fetchPublicDocuments } from '../../../utils/ApiDocsUtilities';
import ImagesModal from '../../style/Modals/ImagesModal';
import DocumentTableHome from '../DocumentTableHome';
import DocumentPreviewHome from '../DocumentPreviewHome';
import DocumentGenerator from '../DocumentGeneration/DocumentGenerator';
import SelectOptionModalContent from '../../style/Modals/SelectAndAddModal/SelectOptionModalContent';
import AddModalContent from '../../style/Modals/SelectAndAddModal/AddModalContent';
import { filterAndGenerateDocumentWithOption } from '../../../utils/apiUtilities';
import ModelPreviewModal from '../Modal/PdfPreviewHomeModal';
import PdfPreviewHomeModal from '../Modal/PdfPreviewHomeModal';
import { HomePageContainer } from '../../Containers/HomePageContainer';
import { DocumentManagerContainer } from '../../Containers/DocumentManagerContainer';
import Typography from '../../style/Typography';
import { Link } from 'react-router-dom';


function DocumentManagerHome() {
    const [listDocument, setListDocument] = useState([]);
    const [isFetchloading, setisFetchloading] = useState(true);
    const [isModalLoading, setIsModalLoading] = useState(false);

    const [selectedDocumentData, setSelectedDocumentData] = useState(null);
    const [isImagesModalOpen, setIsImagesModalOpen] = useState(false);
    const [isGenerating, setIsGenerating] = useState(false);

    const [showSelectOptionsModal, setSelectShowOptionsModal] = useState(false);
    const [showAddOptionsModal, setAddShowOptionsModal] = useState(false);

    const [isPdfPreviewOpen, setIsPdfPreviewOpen] = useState(false);
    
    // Fonction pour ouvrir la modal avec le document
    const handleOpenPdfPreview = (document) => {
        setSelectedDocumentData(document);
        setIsPdfPreviewOpen(true);
    };

    // Fonction pour fermer la modal
    const handleClosePdfPreview = () => {
        setIsPdfPreviewOpen(false);
    };


    useEffect(() => {
        const fetchDocuments = async () => {
            try {
                setisFetchloading(true);
                const data = await fetchPublicDocuments();
                console.log("data", data)
                setListDocument(data);
            } catch (error) {
                console.error('Erreur lors de la récupération des documents:', error);
            } finally {
                setisFetchloading(false);
            }
        };
        fetchDocuments();
    }, []);

    // Nouveau useEffect pour sélectionner automatiquement le premier document
    useEffect(() => {
        if (listDocument.length > 0 && selectedDocumentData == null) {
            setSelectedDocumentData(listDocument[0]);
        }
    }, [listDocument]);

    const handleSelectDocument = (document) => {
        setSelectedDocumentData(document); // Définit uniquement le document sélectionné pour l’aperçu dans DocumentPreviewHome
    };

    const handleGenerateDocument = (document) => {
        setSelectedDocumentData(document);
        setIsGenerating(true);  // Basculer en mode génération
    };

    const handleSubmitOptions = async (userChoices) => {
        setIsModalLoading(true)
        try {
            
            // Appelez le endpoint pour générer le document avec les options sélectionnées
            const response = await filterAndGenerateDocumentWithOption(userChoices, selectedDocumentData);
            console.log("user choice",userChoices )
            // Met à jour l'état `filteredDocumentData` avec la réponse
            setSelectedDocumentData(response.document_data);
            console.log("Donnée filtré du nouvel API", response.document_data)

            // Utilise le document sélectionné avec les données filtrées
            handleGenerateDocument(response.document_data);
        } catch (error) {
            console.error('Erreur lors de la génération du document avec options:', error);
        } finally {
            setIsModalLoading(false);  // Désactiver le chargement
            setSelectShowOptionsModal(false);
        }
    };

    const handleSelectShowOptionsModal = (document) => {
        setSelectedDocumentData(document);
        setSelectShowOptionsModal(true);
    };

    const handleDocumentWithOptions = (document) => {
        setSelectedDocumentData(document);
        setAddShowOptionsModal(false); // Fermer la modal d'ajout
        setSelectShowOptionsModal(true); // Ouvrir la modal de sélection d'options
    };
    

    const handleAddShowOptionsModal = (document = null) => {
    console.log("handleAddShowOptionsModal")

        // Si un document est passé, on le définit comme document sélectionné
        if (document) {
            setSelectedDocumentData(document);
        } else {
            // Sinon, réinitialise les données sélectionnées pour un nouveau document
            setSelectedDocumentData(null);
        }
        // Ouvre la modal
        setAddShowOptionsModal(true);
    };
    

    const getImageUrl = (path) => {
        return path ? `${process.env.REACT_APP_API_URL}${path}` : null;
    };

    const handleBackToDocumentList = () => {
        console.log("BacktoDocumentlist")
        setSelectShowOptionsModal(false)
        setAddShowOptionsModal(false)
        setIsGenerating(false);
        setSelectedDocumentData(null);
        if (listDocument.length > 0) {
            setSelectedDocumentData(listDocument[0]); // Sélectionne automatiquement le premier document
        } else {
            setSelectedDocumentData(null);
        }
    };

    return (
        <DocumentManagerContainer>
            {isGenerating ? (
                <div className="w-full mx-auto h-full p-2">
                    <DocumentGenerator
                        documentData={selectedDocumentData}
                        onBack={handleBackToDocumentList}
                    />
                </div>
            ) : (
                <div className="flex flex-col w-full h-full p-2 space-y-2">
                    {/* Section d'introduction (placée en dehors de Preview et Table) */}
                    <div className="bg-white text-gray-700 p-4 rounded-lg ">
                    <Typography 
                        variant="caption1" 
                        weight="bold" 
                        className="text-primary-600 text-center"
                    >
                        Bienvenue dans la bibliothèque
                    </Typography>

                    <Typography 
                        variant="caption3" 
                        theme='gray-700'
                        className="mt-2"
                    >
                        Découvrez une collection de documents prêts à l'emploi spécialement pour vous ! <br />
                        Sélectionnez le modèle de votre choix, complétez les champs et générez votre document en quelques clics. <br />
                        Vous pouvez également personnaliser et ajouter vos propres modèles. Consultez notre <a href="/how-it-works" className="text-primary-600 hover:underline">guide</a> pour vous aider à démarrer.
                    </Typography>

                    </div>

                    {/* Conteneur principal pour Preview et Table */}
                    <div className="flex flex-col md:flex-row w-full h-full rounded-lg bg-white overflow-hidden">
                        {/* Preview à gauche */}
                        <div className="w-full md:w-2/4 h-full">
                            <DocumentPreviewHome 
                                document={selectedDocumentData} 
                                getImageUrl={getImageUrl} 
                                onOpenImageModal={() => handleOpenPdfPreview(selectedDocumentData)}
                            />
                        </div>

                        {/* Table à droite */}
                        <div className="w-full md:w-2/4 h-full overflow-auto">
                            <DocumentTableHome
                                documents={listDocument}
                                isFetchloading={isFetchloading}
                                onSelectDocument={handleSelectDocument}
                                onGenerateDocument={handleGenerateDocument}
                                onSelectShowOptionsModal={handleSelectShowOptionsModal}
                                onAddShowOptionsModal={handleAddShowOptionsModal}
                            />
                        </div>

                    {isPdfPreviewOpen && (
                        <PdfPreviewHomeModal
                            isOpen={isPdfPreviewOpen}
                            onClose={handleClosePdfPreview}
                            documentData={selectedDocumentData}
                        />
                    )}

                    {/* Modals */}
                    {showSelectOptionsModal && (
                        <SelectOptionModalContent 
                            options={selectedDocumentData.document_metadata.options}
                            onClose={handleBackToDocumentList}
                            isOpen={showSelectOptionsModal}
                            onSubmit={handleSubmitOptions}
                            isModalLoading={isModalLoading}
                        />
                    )}

                    {showAddOptionsModal && (
                        <AddModalContent 
                            options={selectedDocumentData?.document_metadata?.options || []}
                            onClose={handleBackToDocumentList}
                            isOpen={showAddOptionsModal}
                            onSubmitWithOption={handleDocumentWithOptions}
                            onSubmitNoOption={handleGenerateDocument}
                            isModalLoading={isModalLoading}
                        />
                    )}
                </div>
                </div>
            )}
        </DocumentManagerContainer>
    );
    
}

export default DocumentManagerHome;
