import React, { useState } from 'react';
import Typography from '../../../components/style/Typography';
import Button from '../../../components/style/Button';
import { HomePageContainer } from '../../../components/Containers/HomePageContainer';
import { useNavigate } from 'react-router-dom';

import pain1 from '../../../assets/svg/pain1.jpg';


const painPoints = [
    {
        title: "Des heures perdues sur des tâches répétitives",
        description: "Remplir manuellement chaque document, rechercher des informations dans plusieurs fichiers, copier-coller des données… Ces processus répétitifs et chronophages vous font perdre un temps précieux que vous pourriez consacrer à des activités plus enrichissantes.",
        image: pain1,
    },
    {
        title: "Une charge mentale constante",
        description: "Entre les deadlines serrées, les documents à préparer et les urgences qui s’ajoutent sans prévenir, la charge mentale ne fait qu’augmenter. Vous finissez souvent vos journées en ayant l’impression de ne jamais en faire assez, malgré vos efforts.",
        image: pain1,
    },
    {
        title: "La difficulté de déconnecter",
        description: "Votre travail vous suit partout : le soir, le week-end, et parfois même pendant les vacances. Ces documents qui s’accumulent et ces tâches à finaliser restent dans un coin de votre tête, vous empêchant de profiter pleinement de votre temps libre.",
        image: pain1,
    },
    {
        title: "Une pression à toujours tout bien faire",
        description: "En tant que RH freelance, chaque erreur dans un document peut impacter votre crédibilité. Cette pression constante à tout vérifier, à tout relire, et à tout perfectionner vous laisse peu de place pour respirer et vous sentir sereine.",
        image: pain1,
    },
    {
        title: "Un équilibre vie pro/perso difficile à atteindre",
        description: "Jongler entre vos responsabilités professionnelles et votre vie personnelle devient un défi quotidien. Les tâches administratives prennent tellement de temps et d’énergie qu’il est difficile de trouver des moments pour soi ou sa famille.",
        image: pain1,
    }
];

const HomePagePain = () => {
    const [activeIndex, setActiveIndex] = useState(0); // Définit le premier pain point comme actif par défaut

    const handleToggle = (index) => {
        console.log("Clicked index:", index); // Pour vérifier quel index est cliqué
        setActiveIndex(index); // Met à jour l'index actif
    };

    return (
        <section className="bg-gray-50 py-16">
            <HomePageContainer className="space-y-12">
                {/* Titre et sous-titre */}
                <div className="text-center">
                    <Typography variant="h3" weight="bold" className="text-primary-600">
                        Quand gérer vos documents devient une source de frustration…
                    </Typography>
                    <Typography variant="caption3" theme="gray-600" className="mt-4">
                        Rédiger manuellement chaque document, jongler entre des modèles, vérifier chaque détail… Vous méritez mieux.
                    </Typography>
                </div>

                <div className="flex flex-col md:flex-row md:space-x-12">
                    {/* Conteneur fixe pour l'image */}
                    <div
                        className="flex-1 flex items-center justify-center bg-gray-100 rounded-lg shadow-md"
                        style={{ minHeight: "300px", maxHeight: "400px" }}
                    >
                        {activeIndex !== null ? (
                            <img
                                src={painPoints[activeIndex].image}
                                alt={painPoints[activeIndex].title}
                                className="w-full h-auto rounded-lg transition-transform duration-300 transform hover:scale-105"
                            />
                        ) : (
                            <Typography
                                variant="body-lg"
                                theme="gray-500"
                                className="text-center italic"
                            >
                                Sélectionnez un point pour voir l'image associée.
                            </Typography>
                        )}
                    </div>
                    
                    
                    {/* Liste des Pain Points (dropdowns) */}
                    <div className="flex-1 space-y-4">
                        {painPoints.map((point, index) => (
                            <div key={index} className="border-b border-gray-300 pb-4">
                                <Typography
                                    variant="body-lg"
                                    weight="bold"
                                    className="cursor-pointer text-gray-800 hover:text-primary-600"
                                    onClick={() => handleToggle(index)}
                                >
                                    {point.title}
                                </Typography>
                                {activeIndex === index && (
                                    <Typography
                                        variant="body-base"
                                        theme="gray-600"
                                        className="mt-4 transition-opacity duration-300"
                                    >
                                        {point.description}
                                    </Typography>
                                )}
                            </div>
                        ))}
                    </div>

                    
                </div>
            </HomePageContainer>
        </section>
    );
};

export default HomePagePain;
