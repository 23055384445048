import React from 'react';
import { Img } from "react-image";
import Typography from '../Typography';

const Card = ({ title, description, image }) => {
    return (
        <div className="flex flex-col bg-white rounded-lg shadow-lg hover:shadow-2xl transition-shadow duration-300 overflow-hidden h-full">
            {/* Zone de l'image */}
            <div className="w-full bg-gray-100" style={{ height: '300px' }}>
                <Img
                    src={image}
                    alt={title}
                    loader={<div className="bg-gray-200 animate-pulse w-full h-full rounded"></div>}
                    unloader={<div className="text-gray-500">Image introuvable</div>}
                    className="w-full h-full object-cover"
                />
            </div>
            
            {/* Bordure fine entre image et texte */}
            <div className="border-t border-gray-200"></div>
            
            {/* Zone de texte */}
            <div className="p-6 text-center flex flex-col flex-grow">
                {/* Titre */}
                <Typography variant="h5" weight="bold" theme="gray-700" className="mb-4">
                    {title}
                </Typography>
                
                {/* Description */}
                <Typography variant="body-base" theme="gray-600" className="flex-grow">
                    {description}
                </Typography>
            </div>
        </div>
    );
};

export default Card;
