// src/components/style/Loader.jsx
import React from 'react';
import PropTypes from 'prop-types';
import clsx from "clsx";

function Loader({ size = 'md', color = 'primary' }) {
    const getSizeClasses = (size) => {
        switch (size) {
            case 'xs':
                return 'w-4 h-4';
            case 'sm':
                return 'w-6 h-6';
            case 'md':
                return 'w-8 h-8';
            case 'lg':
                return 'w-12 h-12';
            case 'xl':
                return 'w-16 h-16';
            default:
                return 'w-8 h-8';
        }
    };

    const getColorClass = (color) => {
        switch (color) {
            case 'primary':
                return 'text-primary';
            case 'primary-600':
                return 'text-primary-600';
            case 'secondary':
                return 'text-secondary';
            case 'secondary-400':
                return 'text-secondary-400';
            case 'alert-danger':
                return 'text-alert-danger';
            case 'alert-success':
                return 'text-alert-success';
            default:
                return 'text-gray-300'; // Default color
        }
    };

    return (
        <svg
            role="status"
            className={clsx(getSizeClasses(size), getColorClass(color), "animate-spin")}
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            aria-label="Chargement..."
        >
            <circle
                className="opacity-25"
                cx="12"
                cy="12"
                r="10"
                stroke="currentColor"
                strokeWidth="4"
            ></circle>
            <path
                className="opacity-75"
                fill="currentColor"
                d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
            ></path>
        </svg>
    );
}

Loader.propTypes = {
    size: PropTypes.oneOf(['xs', 'sm', 'md', 'lg', 'xl']),
    color: PropTypes.string,
};

export default Loader;
