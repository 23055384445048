import React from 'react';
import PropTypes from 'prop-types';
import Loader from '../Loader';
import clsx from 'clsx';

function Button({ 
    variant = 'primary', 
    size = 'md', 
    children, 
    onClick, 
    disabled, 
    isLoading, 
    className = '', 
    type = 'button',
    icon: Icon,
    iconTheme = 'accent',
    iconPosition = 'left',
    iconSize = 20,
}) {
    let variantClass = '';
    let loaderColor = '';

    // Gestion des variantes
    switch (variant) {
        case 'primary':
            variantClass = 'bg-primary-600 text-white rounded hover:bg-primary-400 shadow-md';
            loaderColor = 'white';
            break;
        case 'secondary':
            variantClass = 'bg-primary-200 shadow-md hover:shadow-lg hover:bg-primary transition-shadow font-semibold rounded text-primary-600';
            loaderColor = 'primary-600';
            break;
        case 'tertiary':
            variantClass = 'bg-secondary shadow-md hover:shadow-lg transition-shadow font-semibold rounded text-white';
            loaderColor = 'secondary';
            break;
        case 'outline':
            variantClass = 'border border-primary-600 text-primary-600 hover:bg-primary-100';
            loaderColor = 'primary-600';
            break;
        case 'danger':
            variantClass = 'bg-red-600 text-white hover:bg-red-500';
            loaderColor = 'white';
            break;
        case 'ico':
            if (iconTheme === 'accent') {
                variantClass = 'bg-primary hover:bg-primary-400 text-white rounded-full';
            } else if (iconTheme === 'secondary') {
                variantClass = 'bg-primary-200 hover:bg-primary-300 text-primary rounded-full';
            } else if (iconTheme === 'gray') {
                variantClass = 'bg-gray-800 hover:bg-gray-700 text-white rounded-full';
            } else if (iconTheme === 'white') {
                variantClass = 'bg-white hover:bg-gray-50 text-gray-700 rounded-full';
            }
            loaderColor = 'white';
            break;
        default:
            variantClass = 'bg-primary-600 text-white hover:bg-primary-500';
            loaderColor = 'white';
    }

    // Gestion des tailles
    let sizeClass = '';
    switch (size) {
        case 'sm':
            sizeClass = variant === 'ico' 
                ? 'flex items-center justify-center w-[40px] h-[40px]' 
                : 'text-caption3 font-medium px-[14px] py-[11px]';
            break;
        case 'md':
            sizeClass = variant === 'ico' 
                ? 'flex items-center justify-center w-[50px] h-[50px]' 
                : 'text-caption2 font-medium px-[18px] py-[13px]';
            break;
        case 'lg':
            sizeClass = variant === 'ico' 
                ? 'flex items-center justify-center w-[60px] h-[60px]' 
                : 'text-caption1 font-medium px-[22px] py-[15px]';
            break;
        default:
            sizeClass = 'text-caption2 font-medium px-[18px] py-[13px]';
    }

    const disabledClass = (disabled || isLoading) ? 'opacity-50 cursor-not-allowed' : '';

    const buttonClasses = clsx(
        'relative flex items-center justify-center focus:outline-none transition-all',
        variantClass,
        sizeClass,
        disabledClass,
        className
    );

    return (
        <button 
            onClick={onClick} 
            className={buttonClasses} 
            disabled={disabled || isLoading} 
            type={type}
        >
            {/* Loader */}
            {isLoading && (
                <div className="absolute inset-0 flex items-center justify-center">
                    <Loader size="sm" color={loaderColor} />
                </div>
            )}

            {/* Contenu du bouton */}
            <div className={clsx(
                "flex items-center gap-2",
                isLoading && "invisible"
            )}>
                {Icon && variant === 'ico' ? (
                    <Icon size={iconSize} />
                ) : (
                    <>
                        {Icon && iconPosition === 'left' && <Icon size={iconSize} />}
                        {children}
                        {Icon && iconPosition === 'right' && <Icon size={iconSize} />}
                    </>
                )}
            </div>
        </button>
    );
}

Button.propTypes = {
    variant: PropTypes.oneOf(['primary', 'secondary', 'tertiary', 'outline', 'danger', 'ico']),
    size: PropTypes.oneOf(['sm', 'md', 'lg']),
    children: PropTypes.node,
    onClick: PropTypes.func,
    disabled: PropTypes.bool,
    isLoading: PropTypes.bool,
    className: PropTypes.string,
    type: PropTypes.oneOf(['button', 'submit', 'reset']),
    icon: PropTypes.elementType,
    iconTheme: PropTypes.oneOf(['accent', 'secondary', 'gray', "white"]),
    iconPosition: PropTypes.oneOf(['left', 'right']),
    iconSize: PropTypes.number,
};

export default Button;
