// utils/apiUtilities.js

export const uploadAndProcessLogo = async (fileContent, fileName) => {
    const payload = {
        fileContent,
        fileName,
    };

    const response = await fetch(`${process.env.REACT_APP_API_URL}/upload-and-process-logo`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(payload),
    });

    if (!response.ok) {
        console.error("Erreur lors de l'appel à uploadAndProcessLogo:", response.status, response.statusText);
        throw new Error('Erreur lors de l\'upload et du traitement du logo');
    }

    return await response.json(); // Retourne les données (par exemple, l'URL de l'image)
};


export const filterAndGenerateDocumentWithOption = async (userChoices, documentData) => {
    const payload = {
        user_choices: userChoices,
        document_data: documentData,
    };

    const response = await fetch(`${process.env.REACT_APP_API_URL}/filter-and-generate-document-with-option`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(payload),
    });

    if (!response.ok) {
        console.error("Erreur lors de l'appel API filterAndGenerateDocumentWithOption:", response.status);
        throw new Error('Erreur lors du filtrage et de la génération du document avec options');
    }

    return await response.json();  // Récupérer et retourner la réponse JSON
};

export const extractAndReplacePlaceholders = async (userInputs, isWord, isPDF, documentData) => {
    const requestBody = {
        user_input_choice: userInputs,
        generate_word: isWord,
        generate_pdf: isPDF,
        document_data: documentData,
    };
    const response = await fetch(`${process.env.REACT_APP_API_URL}/extract-replace-placeholders`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(requestBody),
    });

    if (!response.ok) {
        console.log("Erreur de la réponse API, status:", response.status);
        throw new Error('Erreur lors de l\'extraction et du remplacement des placeholders');
    }

    return await response.json();  // Retourner les données de la réponse
};

export const uploadAndExtractPlaceholders = async (file, isAdmin = false) => {
    const formData = new FormData();
    formData.append('file', file);
    formData.append('isAdmin', isAdmin);  // Ajouter le paramètre isAdmin
    console.log("Value du isAdmin", isAdmin)

    const response = await fetch(`${process.env.REACT_APP_API_URL}/upload-and-extract`, {
        method: 'POST',
        body: formData,
    });

    if (!response.ok) {
        throw new Error('Erreur lors du traitement du document.');
    }

    return await response.json();
};


export const generateDocumentWithOption = async (userChoices, documentData) => {
    const payload = {
        user_choices: userChoices,
        document_data: documentData,
    };

    const response = await fetch(`${process.env.REACT_APP_API_URL}/generate-document-with-option`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(payload),
    });

    if (!response.ok) {
        throw new Error('Erreur lors de la génération du document avec options');
    }

    return await response.json();  // Récupérer la réponse JSON
};

