// ApiUsersUtilities.jsx

const apiRequest = async (endpoint, options = {}) => {
    const token = localStorage.getItem('access_token');

    const headers = {
        'Content-Type': 'application/json',
        ...(token ? { Authorization: `Bearer ${token}` } : {}),
        ...options.headers,
    };

    const response = await fetch(`${process.env.REACT_APP_API_URL}${endpoint}`, {
        ...options,
        headers,
    });

    if (!response.ok) {
        const errorData = await response.json().catch(() => ({}));
        throw new Error(errorData.detail || errorData.message || 'Erreur API');
    }

    return await response.json();
};

export const registerUser = async (userData) => {
    return await apiRequest('/register', {
        method: 'POST',
        body: JSON.stringify(userData),
    });
};


export const fetchCurrentUser = async () => {
    const token = localStorage.getItem('access_token');
    // console.log("FetchCurrentUser access_token :", token)
      
    const response = await fetch(`${process.env.REACT_APP_API_URL}/users/me`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            ...(token ? { Authorization: `Bearer ${token}` } : {}), // Ajout de l'Authorization header si le token est disponible
        },
    });

    if (!response.ok) {
        throw new Error('Erreur lors de la récupération des informations utilisateur');
    }

    return await response.json();
};



export const fetchAllUsers = async () => {
    return await apiRequest('/users', {
        method: 'GET',
        credentials: 'include', // Inclut les cookies si nécessaire
    });
};


export const deleteUser = async (userId) => {
    console.log("ID de l'utilisateur à supprimer :", userId);
    return await apiRequest(`/users/${userId}`, {
        method: 'DELETE',
    });
};


export const updateUser = async (userId, updatedUser) => {
    return await apiRequest(`/users/${userId}`, {
        method: 'PUT',
        body: JSON.stringify(updatedUser),
    });
};
