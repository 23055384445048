import React from "react";
import Typography from "../style/Typography";
import { FooterContainer } from "../Containers/FooterContainer";
import { footerLinks } from "../../data/footerLinks";
import { v4 as uuidv4 } from "uuid";
import { Link } from "react-router-dom";
import SocialNetworksButtons from "../style/SocialNetworksButtons";
import youtubeLogo from "../../assets/svg/YTB.svg";

const Footer = () => {
  const currentYear = new Date().getFullYear();

  // Générer les colonnes de navigation
  const footerNavigationList = footerLinks.map((columnLinks, index) => (
    <FooterLink key={uuidv4()} data={columnLinks} index={index} />
  ));

  return (
    <div className="bg-grey text-white">
      {/* Section principale */}
      <FooterContainer className="flex flex-col lg:flex-row lg:justify-between lg:items-start pt-16 gap-10">
        {/* Section Youtube */}
        <div className="flex flex-col items-center lg:items-start gap-4 flex-shrink-0">
            <Typography
                variant="caption1"
                theme="white"
                weight="medium"
            >
                Découvre Bara Doc
            </Typography>
            <Typography
                variant="caption3"
                className="text-gray-400 text-center lg:text-left"
            >
                Abonnes-toi à la chaîne !
            </Typography>
            <a href="https://www.youtube.com/" target="_blank" rel="noopener noreferrer">
                <img
                    src={youtubeLogo}
                    alt="Bara Doc | Youtube"
                    className="max-w-[150px] h-auto" // Limite la largeur de l'image
                />
            </a>
        </div>

        {/* Navigation Footer */}
        <div className="flex flex-col sm:flex-row justify-between gap-6 lg:gap-10">
            {footerNavigationList}
        </div>
    </FooterContainer>

      {/* Section secondaire */}
      <FooterContainer className="pt-6 pb-8 md:pt-9 md:pb-11 space-y-4">
        <hr className="text-gray-700" />
        <div className="flex flex-col md:flex-row items-center justify-between gap-4">
          <Typography
            variant="caption4"
            className="text-gray-400 text-center md:text-left text-sm"
          >
            {`Copyright © ${currentYear} | Propulsed by `}
            <a
              href="https://www.linkedin.com/in/marc-eliecharleston/"
              target="_blank"
              className="underline"
              rel="noreferrer"
            >
              Marc-Elie Charleston
            </a>
          </Typography>
          <SocialNetworksButtons theme="gray" />
        </div>
      </FooterContainer>
    </div>
  );
};

const FooterLink = ({ data, index }) => {
  // Masquer certaines colonnes inutiles sur mobile
  const isHiddenOnMobile = index > 1; // Cacher après les deux premières colonnes
  return (
    <div
      className={`min-w-[190px] ${
        isHiddenOnMobile ? "hidden md:block" : ""
      }`}
    >
      <Typography
        theme="white"
        variant="caption2"
        weight="medium"
        className="pb-5"
      >
        {data.label}
      </Typography>
      <Typography variant="caption3" className="space-y-4 text-gray-400 text-sm">
        {data.links.map((link) => (
          <div key={uuidv4()}>
            {link.type === "internal" && (
              <Link to={link.baseUrl} className="hover:underline">
                {link.label}
              </Link>
            )}
            {link.type === "external" && (
              <a
                href={link.baseUrl}
                target="_blank"
                rel="noreferrer"
                className="hover:underline"
              >
                {link.label}
              </a>
            )}
          </div>
        ))}
      </Typography>
    </div>
  );
};

export default Footer;
