import React, { useEffect, useState } from 'react';
import LibraryComponent from '../LibraryComponent';
import { fetchPublicDocuments } from '../../../../utils/ApiDocsUtilities';
import { FaMagnifyingGlass, FaArrowRightFromBracket } from 'react-icons/fa6';

function LibraryContainer() {
    const [listDocument, setListDocument] = useState([]);
    const [isFetchLoading, setIsFetchLoading] = useState(false);

    useEffect(() => {
        const fetchDocuments = async () => {
            try {
                setIsFetchLoading(true);
                const data = await fetchPublicDocuments(); // Appelle la fonction API
                setListDocument(data);
            } catch (error) {
                console.error('Erreur lors de la récupération des documents:', error);
            } finally {
                setIsFetchLoading(false);
            }
        };
        fetchDocuments();
    }, []);

    if (isFetchLoading) {
        return <div>Chargement des documents...</div>;
    }

    // Configuration des colonnes d'icônes
    const iconColumns = [
        {
            label: 'Générer',
            type: 'icon',
            render: (row) => (
                <button
                    onClick={(e) => {
                        e.stopPropagation();
                        console.log('Générer clicked for:', row);
                    }}
                    className="w-8 h-8 flex items-center justify-center rounded-full text-blue-500 hover:text-blue-700 hover:scale-110 transition-transform duration-150"
                >
                    <FaArrowRightFromBracket size={16} />
                </button>
            ),
        },
        {
            label: 'Voir',
            type: 'icon',
            render: (row) => (
                <button
                    onClick={(e) => {
                        e.stopPropagation();
                        console.log('Voir clicked for:', row);
                    }}
                    className="w-8 h-8 flex items-center justify-center rounded-full text-gray-500 hover:text-gray-700 hover:scale-110 transition-transform duration-150"
                >
                    <FaMagnifyingGlass size={16} />
                </button>
            ),
        },
    ];

    return (
        <div>
            <h2 className="text-2xl font-bold mb-4 text-center">Bibliothèque</h2>
            <LibraryComponent docs={listDocument} iconColumns={iconColumns} />
        </div>
    );
}

export default LibraryContainer;
