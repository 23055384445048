import React, { useState } from 'react';
import AddOptionModalContainer from '../AddOptionModalContainer';
import UploadDocument from '../../../../../components/Home/Modal/ModalUtilities/UploadDocument'
import Loader from '../../../Loader'; // Composant de chargement
import { uploadAndExtractPlaceholders } from '../../../../../utils/apiUtilities';

import TextLink from '../../../TextLink';
import Button from '../../../Button';
import Typography from '../../../Typography';

import { FaArrowRightLong } from "react-icons/fa6";

function AddModalContent({
    isOpen,
    onClose,
    onSubmitWithOption,
    onSubmitNoOption,
}) {
    const [selectedFile, setSelectedFile] = useState(null);
    const [isLoading, setIsLoading] = useState(false);

    // Enregistre le fichier sélectionné, sans l'envoyer
    const handleFileChange = (file) => {
        setSelectedFile(file);
    };

    // Envoie et traite le fichier lorsque l'utilisateur clique sur "Suivant"
    const handleFileUpload = async () => {
        if (!selectedFile) return;

        setIsLoading(true);
        try {
            // Appel API pour uploader et analyser le fichier
            const response = await uploadAndExtractPlaceholders(selectedFile);
            console.log("Addmodal - response", response)

            // Vérifie si le document a des options
            const hasOptions = response.document_metadata.options && response.document_metadata.options.length > 0;
            console.log("Addmodal - HasOption", hasOptions)

            if (hasOptions) {
                onSubmitWithOption(response); // Passe à la gestion avec options
            } else {
                onSubmitNoOption(response); // Génération directe
                
            }
        } catch (error) {
            console.error("Erreur lors de l'ajout du document :", error);
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <AddOptionModalContainer title="Ajouter un document" isOpen={isOpen} onClose={onClose}>
            <div className="flex flex-col items-center justify-center w-full h-full">

                {/* Header */}
                <div className="w-full text-center mb-6 py-2">
                    <Typography variant="lead" weight="bold" className="text-primary-600">
                        Charger votre document
                    </Typography>
                    <p className="text-gray-500">Veuillez sélectionner un document Word pour continuer.</p>
                </div>

                {isLoading ? (
                    <div className="flex flex-col items-center">
                        <Loader size="lg" color="primary" />
                        <p className="text-gray-500 mt-4">Chargement du document, veuillez patienter...</p>
                    </div>
                ) : (
                    <>
                        <UploadDocument onUpload={handleFileChange} />
                        
                        {/* Footer */}
                        <div className="flex justify-between items-center w-full mt-8">
                            <TextLink variant="retour" size="md" onClick={onClose}>
                                Annuler
                            </TextLink>
                            <Button 
                                variant="primary" 
                                size="sm" 
                                onClick={handleFileUpload}
                                disabled={!selectedFile} // Désactiver tant qu'aucun fichier n'est sélectionné
                            >
                                {selectedFile ? 'Suivant' : 'Valider'}
                            </Button>
                        </div>
                    </>
                )}
            </div>
        </AddOptionModalContainer>
    );
}

export default AddModalContent;
