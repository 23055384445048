import React from 'react';
import Typography from '../../style/Typography';
import Loader from '../../style/Loader';

function DocumentPreviewHome({ document, getImageUrl, className, onOpenImageModal }) {
    return (
        <div className={`${className} h-full p-4 flex flex-col`}>
            {document ? (
                <>
                    <Typography variant="caption1" weight="medium" className="mb-4 text-center">
                        Aperçu de {document.name}
                    </Typography>

                    {/* Aperçu du document */}
                    <div className="flex-grow w-full h-full bg-gray-100 border border-gray-300 overflow-hidden flex items-center justify-center shadow-md">
                        {document.document_metadata?.png_urls?.length > 0 ? (
                            <img
                                src={getImageUrl(document.document_metadata.png_urls[0])}
                                alt="Aperçu du document"
                                className="w-full h-full object-cover" // Mise à jour des styles
                                style={{ maxHeight: '100%', objectPosition: 'top' }} // Centrer et couper les bords inférieurs si nécessaire
                                onClick={() => onOpenImageModal(document.document_metadata.png_urls.map(getImageUrl))}
                            />
                        ) : (
                            <div className="flex flex-col items-center">
                                <Loader size="lg" color="primary" />
                                <p className="text-gray-500 mt-4">Chargement de l'image...</p>
                            </div>
                        )}
                    </div>
                </>
            ) : (
                <Typography variant="body-lg" theme="gray" className="text-lg">
                    Sélectionnez un document pour voir les détails.
                </Typography>
            )}
        </div>
    );
}

export default DocumentPreviewHome;
