import React from 'react';
import TableDocument from '../../../style/TableDocument';

function LibraryComponent({ docs, iconColumns = [] }) {
    // Configurez les colonnes pour le tableau
    const baseColumns = [
        { label: 'Nom du document', key: 'name', width: '1/4' },
        { label: 'Catégorie', key: 'category', width: '1/4' },
        {
            label: 'Créé le',
            key: 'created_at',
            width: '1/4',
            render: (value) => new Date(value).toLocaleDateString(), // Formate la date
        },
    ];

    // Fusionnez les colonnes de base avec les colonnes d'icônes
    const columns = [...baseColumns, ...iconColumns];

    return (
        <div className="p-4 border rounded shadow-sm">
            <TableDocument
                columns={columns}
                data={docs}
                onRowClick={(row) => console.log('Document cliqué:', row)} // Gère le clic sur une ligne
            />
        </div>
    );
}

export default LibraryComponent;
