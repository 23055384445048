import React from 'react';
import { useUser } from '../../../../context/User/UserContext';

function AdminBoardComponent() {
    const { user, logout } = useUser();

    return (
        <div>
            <h1>Bienvenue, {user?.name}</h1>
            <h2>Entité : {user?.entity?.name}</h2>
            <h3>Entreprises :</h3>
            <ul>
                {user?.entity?.companies.map((company) => (
                    <li key={company.id}>{company.name}</li>
                ))}
            </ul>
            <button onClick={logout}>Déconnexion</button>
        </div>
    );
}

export default AdminBoardComponent;
