import React from 'react';
import DocumentManagerHome from '../../components/Home/DocumentManagerHome';

const DocumentManagerPage = () => {
    return (
        <div className="w-full h-full bg-gradient-to-b from-primary-300 to-white">
            <DocumentManagerHome />
        </div>
    );
};

export default DocumentManagerPage;
