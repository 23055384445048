import React from 'react';

function TutorialComponent({ title, description }) {
    return (
        <div className="p-4 border rounded shadow-sm mb-4">
            <h3 className="font-semibold">{title}</h3>
            <p>{description}</p>
            <button className="mt-2 p-2 bg-blue-600 text-white rounded">Regarder la vidéo</button>
        </div>
    );
}

export default TutorialComponent;
