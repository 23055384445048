import React, { useState } from 'react';
import { registerUser } from '../../../utils/Api/ApiUsersUtilities';

function AddUserForm({ onSave, onCancel }) {
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [role, setRole] = useState('utilisateur');  // Valeur par défaut
    const [error, setError] = useState(null);  // Pour gérer les erreurs d'API

    const handleSave = async () => {
        console.log("AddUserForum - Handlesave")
        if (!name || !email || !password) {
            setError("Tous les champs sont obligatoires");
            return; 
        }
        try {
            setError(null);
            const newUser = { username: name, mail: email, password, global_role: role };
            onSave(newUser); // Passer les données de l'utilisateur au parent
            setName('');
            setEmail('');
            setPassword('');
            setRole('utilisateur');
        } catch (err) {
            setError(err.message);
        }
    };
    

    return (
        <div className="space-y-4">
            <div>
                <label>Nom</label>
                <input
                    type="text"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    className="border p-2 rounded w-full"
                />
            </div>
            <div>
                <label>Email</label>
                <input
                    type="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    className="border p-2 rounded w-full"
                />
            </div>
            <div>
                <label>Mot de passe</label>
                <input
                    type="password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    className="border p-2 rounded w-full"
                />
            </div>
            <div>
                <label>Rôle</label>
                <select
                    value={role}
                    onChange={(e) => setRole(e.target.value)}
                    className="border p-2 rounded w-full"
                >
                    <option value="SUPERADMIN">SUPERADMIN</option>
                    <option value="ADMIN">ADMIN</option>
                    <option value="USER">USER</option>
                    <option value="GUEST">GUEST</option>
                </select>
            </div>
            {error && <p style={{ color: 'red' }}>{error}</p>}
            <div className="flex justify-end space-x-2">
                <button onClick={handleSave} className="bg-blue-500 text-white p-2 rounded">
                    Enregistrer
                </button>
                <button onClick={onCancel} className="bg-gray-500 text-white p-2 rounded">
                    Annuler
                </button>
            </div>
        </div>
    );
}

export default AddUserForm;
