import React, { useState } from 'react';
import SideBarComponent from '../SideBarComponent';
import { useNavigate } from 'react-router-dom';
import { useUser } from '../../../../context/User/UserContext';
import Typography from '../../../style/Typography';

import { FiBookOpen, FiUsers, FiSettings, FiLogOut } from 'react-icons/fi';
import { MdOutlineKeyboardDoubleArrowLeft, MdOutlineKeyboardDoubleArrowRight } from "react-icons/md";
import { MdAccountCircle, MdOutlineOndemandVideo } from "react-icons/md";
import { GrDocument } from "react-icons/gr";
import { FaChalkboardUser } from "react-icons/fa6";
import { FaUsers } from "react-icons/fa";
import { SlDocs } from "react-icons/sl";
import { RxDashboard } from "react-icons/rx";


function SidebarContainer({ setActiveComponent: setParentActiveComponent, activeComponent }) {
    const [isSidebarOpen, setIsSidebarOpen] = useState(true);
    const { user, logout } = useUser();
    const navigate = useNavigate(); // Utilisation de useNavigate pour la redirection

    const handleLogout = () => {
        logout(); // Déconnexion
        navigate('/'); // Redirection vers la page d'accueil
    };

    const toggleSidebar = () => {
        setIsSidebarOpen(!isSidebarOpen);
    };

    const handleComponentSelect = (componentName) => {
        setParentActiveComponent(componentName); // Notifie le parent
    };

    return (
        <aside
            className={`bg-gray-50 h-full flex flex-col justify-between border-r shadow-md ${
                isSidebarOpen ? 'w-56' : 'w-16'
            } transition-all duration-600 ease-in-out`}
        >
            {/* Toggle Button pour ouvrir/fermer la sidebar */}
            <div className="p-4 flex justify-end">
                <button
                    onClick={toggleSidebar}
                    className="text-gray-600 hover:text-gray-800 focus:outline-none"
                >
                    {isSidebarOpen ? (
                        <MdOutlineKeyboardDoubleArrowLeft size={24} />
                    ) : (
                        <MdOutlineKeyboardDoubleArrowRight size={24} />
                    )}
                </button>
            </div>
            

            {/* Section supérieure avec les liens principaux */}
            <nav className={`p-6 space-y-2 flex-grow ${isSidebarOpen ? '' : 'items-center'}`}>
                <SideBarComponent
                    icon={<RxDashboard />}
                    name={isSidebarOpen ? 'Tableau de bord' : ''}
                    title="Tableau de bord"
                    onSelect={() => handleComponentSelect('Tableau de bord')}
                    isSidebarOpen={isSidebarOpen}
                    isActive={activeComponent === 'Tableau de bord'} 
                />
                <SideBarComponent
                    icon={<GrDocument />}
                    name={isSidebarOpen ? 'Mes modèles' : ''}
                    title="Mes modèles"
                    onSelect={() => handleComponentSelect('Modèles')}
                    isSidebarOpen={isSidebarOpen}
                    isActive={activeComponent === 'Modèles'}
                />
                <SideBarComponent
                    icon={<FiBookOpen />}
                    name={isSidebarOpen ? 'Bibliothèque' : ''}
                    title="Bibliothèque"
                    onSelect={() => handleComponentSelect('Bibliothèque')}
                    isSidebarOpen={isSidebarOpen}
                    isActive={activeComponent === 'Bibliothèque'}
                />
                <SideBarComponent
                    icon={<FaUsers />}
                    name={isSidebarOpen ? 'Equipe' : ''}
                    title="Equipe"
                    onSelect={() => handleComponentSelect('Equipe')}
                    isSidebarOpen={isSidebarOpen}
                    isActive={activeComponent === 'Equipe'}
                />
                <SideBarComponent
                    icon={<MdOutlineOndemandVideo />}
                    name={isSidebarOpen ? 'Tutoriel' : ''}
                    title="Tutoriel"
                    onSelect={() => handleComponentSelect('Tutoriel')}
                    isSidebarOpen={isSidebarOpen}
                    isActive={activeComponent === 'Tutoriel'}
                    
                />
            </nav>
            
            {/* Bordure avec padding */}
            <div className="px-4 py-2">
                <div className="border-t border-gray-200"></div>
            </div>

            <div className="flex flex-col space-y-1 p-6 border-gray-200">
                {/* Afficher "Utilisateurs" seulement si l'utilisateur est admin */}
                {['ADMIN', 'SUPERADMIN'].includes(user?.global_role) && (
                    <SideBarComponent
                        icon={<FaChalkboardUser />}
                        name={isSidebarOpen ? 'AdminBoard' : ''}
                        title="AdminBoard"
                        onSelect={() => handleComponentSelect('AdminBoard')}
                        isSidebarOpen={isSidebarOpen}
                        isActive={activeComponent === 'AdminBoard'}
                    />
                )}
                {['ADMIN', 'SUPERADMIN'].includes(user?.global_role) && (
                    <SideBarComponent
                        icon={<SlDocs />}
                        name={isSidebarOpen ? 'AdminDocs' : ''}
                        title="AdminDocs"
                        onSelect={() => handleComponentSelect('AdminDocs')}
                        isSidebarOpen={isSidebarOpen}
                        isActive={activeComponent === 'AdminDocs'}
                    />
                )}
                {['ADMIN', 'SUPERADMIN'].includes(user?.global_role) && (
                    <SideBarComponent
                        icon={<FiUsers />}
                        name={isSidebarOpen ? 'Utilisateurs' : ''}
                        title="Utilisateurs"
                        onSelect={() => handleComponentSelect('Utilisateurs')}
                        isSidebarOpen={isSidebarOpen}
                        isActive={activeComponent === 'Utilisateurs'}
                    />
                )}
            </div>
            
            {/* Bordure avec padding */}
            <div className="px-4 py-2">
                <div className="border-t border-gray-200"></div>
            </div>

            {/* Section inférieure avec Compte, Paramètres, et Déconnexion */}
            <div className="flex flex-col space-y-1 p-6 border-gray-200">
                <SideBarComponent
                    icon={<MdAccountCircle />}
                    name={isSidebarOpen ? 'Compte' : ''}
                    title="Compte"
                    onSelect={() => handleComponentSelect('Compte')}
                    isSidebarOpen={isSidebarOpen}
                    isActive={activeComponent === 'Compte'}
                />
                <SideBarComponent
                    icon={<FiSettings />}
                    name={isSidebarOpen ? 'Paramètres' : ''}
                    title="Paramètres"
                    onSelect={() => handleComponentSelect('Paramètres')}
                    isSidebarOpen={isSidebarOpen}
                    isActive={activeComponent === 'Paramètres'}
                />
                <SideBarComponent
                    icon={<FiLogOut />}
                    name={isSidebarOpen ? 'Déconnexion' : ''}
                    title="Déconnexion"
                    onSelect={handleLogout}
                    isSidebarOpen={isSidebarOpen}
                />
            </div>
        </aside>
    );
    
}

export default SidebarContainer;
