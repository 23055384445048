import React from "react";
import { footerSocialNetworksLinks } from '../../../data/footerLinks'
import { RiFacebookFill } from "react-icons/ri";
import { v4 as uuidv4 } from "uuid";
import clsx from "clsx";
import Button from "../Button";

const SocialNetworksButtons = ({ className = "", theme = "accent" }) => {
    // Génération des boutons des réseaux sociaux
    const icoList = footerSocialNetworksLinks.map((socialNetwork) => (
        <Button
            key={uuidv4()}
            variant="ico"
            iconTheme="gray"
            icon={socialNetwork.icon ? socialNetwork.icon : RiFacebookFill}
            onClick={() => window.open(socialNetwork.baseUrl, "_blank")}
        />
    ));

    return <div className={clsx("flex items-center gap-2.5", className)}>{icoList}</div>;
};

export default SocialNetworksButtons;
