import React, { useState } from 'react';
import { FiFilePlus } from 'react-icons/fi'; // Exemple d'icône

function UploadDocument({ onUpload }) {
    const [selectedFile, setSelectedFile] = useState(null);

    const handleFileChange = (event) => {
        const file = event.target.files[0];
        if (file) {
            setSelectedFile(file);
            console.log("File selected:", file);
            onUpload(file); // On upload simplement le fichier ici, sans changer d'étape
        }
    };
    

    return (
        <div className="flex items-center justify-center p-8
        border border-dashed rounded text-center cursor-pointer hover:bg-gray-100
        border-gray-700">
            <label 
                htmlFor="file-upload" 
                className="flex flex-col items-center cursor-pointer"
            >
                <FiFilePlus className="text-5xl text-gray-500 mb-4" /> {/* Icône pour l'upload */}

                {selectedFile ? (
                    <p className="text-gray-600 mb-4">Fichier sélectionné : <strong>{selectedFile.name}</strong></p>
                ) : (
                    <p className="text-gray-600 mb-4">Sélectionnez un document Word pour commencer</p>
                )}

                <span className="bg-secondary-600 text-white px-4 py-2 rounded hover:bg-secondary-400 shadow-md">
                    {selectedFile ? 'Changer de document' : 'Ajouter un document'}
                </span>
            </label>

            {/* Input file caché */}
            <input 
                id="file-upload" 
                type="file" 
                accept=".doc,.docx" 
                onChange={handleFileChange} 
                className="hidden" // Masquer l'input file
            />
        </div>
    );
}


export default UploadDocument;
