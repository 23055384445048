import React from 'react';

import { MdOutlineKeyboardArrowRight, MdOutlineKeyboardArrowLeft } from "react-icons/md";

function FooterPagination({ currentPage, totalPages, onPageChange, documentsPerPage, documents }) {
    const maxPagesToShow = 5; // Nombre maximum de pages à afficher
    const pages = [];

    // Calcul des pages à afficher
    const startPage = Math.max(1, currentPage - Math.floor(maxPagesToShow / 2));
    const endPage = Math.min(totalPages, startPage + maxPagesToShow - 1);

    for (let i = startPage; i <= endPage; i++) {
        pages.push(i);
    }

    return (
        <div className="flex items-center justify-between py-2 px-8 bg-white rounded-b-lg dark:bg-gray-800">
            {/* Texte avec le nombre d'éléments affichés */}
            <span className="text-sm font-normal text-gray-500 dark:text-gray-400">
                Affichage de
                <span className="font-semibold text-gray-900 dark:text-white ml-1 mr-1">
                    {(currentPage - 1) * documentsPerPage + 1}-{Math.min(currentPage * documentsPerPage, documents.length)}
                </span>
                sur
                <span className="font-semibold text-gray-900 dark:text-white ml-1">
                    {documents.length}
                </span>
            </span>

            {/* Boutons de pagination */}
            <ul className="inline-flex items-center -space-x-px">
                {/* Bouton Précédent */}
                <li>
                    <button
                        onClick={() => {
                            if (currentPage > 1) onPageChange(currentPage - 1);
                        }}
                        disabled={currentPage === 1}
                        className={`flex items-center justify-center h-full py-1.5 px-3 ml-0 text-gray-500 bg-white rounded-l-lg border border-gray-300 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400
                        ${currentPage === 1 ? 'opacity-50 cursor-not-allowed' : 'hover:bg-gray-100 hover:text-gray-700 dark:hover:bg-gray-700 dark:hover:text-white'}`}
                    >
                        <span className="sr-only">Précédent</span>
                        <MdOutlineKeyboardArrowLeft className="w-5 h-5" />
                    </button>
                </li>

                {/* Pages */}
                {pages.map((page) => (
                    <li key={page}>
                        <button
                            onClick={() => onPageChange(page)}
                            className={`flex items-center justify-center px-3 py-2 text-sm leading-tight border ${
                                page === currentPage
                                    ? 'z-10 text-primary-600 bg-primary-50 border-primary-300'
                                    : 'text-gray-500 bg-white border-gray-300 hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white'
                            }`}
                        >
                            {page}
                        </button>
                    </li>
                ))}

                {/* Bouton Suivant */}
                <li>
                    <button
                        onClick={() => {
                            if (currentPage < totalPages) onPageChange(currentPage + 1);
                        }}
                        disabled={currentPage === totalPages}
                        className={`flex items-center justify-center h-full py-1.5 px-3 leading-tight text-gray-500 bg-white rounded-r-lg border border-gray-300 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400
                        ${currentPage === totalPages ? 'opacity-50 cursor-not-allowed' : 'hover:bg-gray-100 hover:text-gray-700 dark:hover:bg-gray-700 dark:hover:text-white'}`}
                    >
                        <span className="sr-only">Suivant</span>
                        <MdOutlineKeyboardArrowRight className="w-5 h-5" />
                    </button>
                </li>
            </ul>
        </div>
    );
}

export default FooterPagination;
