import React from 'react';
import DashboardComponent from '../DashboardComponent';

function DashboardContainer() {
    return (
        <div>
            <h2 className="text-2xl font-bold mb-4">Mon Tableau de bord</h2>
            {/* Ajoute les composants individuels du tableau de bord ici */}
            <DashboardComponent />
        </div>
    );
}

export default DashboardContainer;
