import React from 'react';
import UsersModelsComponent from '../UsersModelsComponent';

function UsersModelsContainer() {
    return (
        <div>
            <h2 className="text-2xl font-bold mb-4">Mes modèles</h2>
            {/* Ajoute ici chaque modèle en utilisant ModelsComponent */}
            <UsersModelsComponent name="Renouvellement PE" category="RH" subcategory="Période d'essai" team="Marketing" />
            <UsersModelsComponent name="Rupture PE" category="RH" subcategory="Période d'essai" team="ALL" />
        </div>
    );
}

export default UsersModelsContainer;
