import React from 'react';
import Typography from '../../../../../style/Typography';

function TextArea({ name, value, onChange }) {
    return (
        <div className="mb-4 w-full flex flex-col items-center"> {/* Assure que le conteneur prend toute la largeur */}
            <Typography
                variant="body-base"
                theme="gray-700"
                className="block w-[70%] mx-auto mb-1"
            >
                {name}
            </Typography>
            <textarea
                defaultValue={value || ''}
                onBlur={(e) => onChange(name, e.target.value)} // Utiliser `onChange` avec la valeur correcte
                className="w-[70%] text-sm mx-auto border border-gray-300 bg-gray-50 px-2 py-1 rounded focus:border-primary focus:shadow-md"
                placeholder={name}
            />
        </div>
    );
}

export default TextArea;
