import React, { useEffect, useRef, useState } from 'react';
import { HomePageContainer } from '../../../components/Containers/HomePageContainer';
import Card from '../../../components/style/Card';
import { useNavigate } from 'react-router-dom';
import Typography from '../../../components/style/Typography';
import Button from '../../../components/style/Button';

import benefit1 from '../../../assets/svg/benefit1.png';
import benefit2 from '../../../assets/svg/benefit2.png';
import benefit3 from '../../../assets/svg/benefit3.png';

const benefits = [
    {
        title: "Modèles prêts à l'emploi",
        description: "Créez vos documents en quelques minutes, sans copier-coller ni perte de temps.",
        image: benefit1,
    },
    {
        title: "Simple et intuitif",
        description: "Sélectionnez un modèle, complétez vos informations en quelques clics, et téléchargez-le instantanément",
        image: benefit2,
    },
    {
        title: "Des modèles personnalisables",
        description: "Ajoutez ou supprimez des sections sans effort, et gagnez du temps à chaque utilisation.",
        image: benefit3,
    },
];

const HomePageBenefits = () => {
    const navigate = useNavigate();

    const sectionRef = useRef(null); // Ref pour la section
    const [isVisible, setIsVisible] = useState(false); // Gère l'état de visibilité

    useEffect(() => {
        const observer = new IntersectionObserver(
            ([entry]) => {
                if (entry.isIntersecting) {
                    setIsVisible(true); // Active l'animation
                }
            },
            { threshold: 0.2 } // L'animation démarre lorsque 20% de la section est visible
        );

        if (sectionRef.current) {
            observer.observe(sectionRef.current);
        }

        return () => {
            if (sectionRef.current) {
                observer.unobserve(sectionRef.current);
            }
        };
    }, []);

    return (
        <section ref={sectionRef} className='bg-gray-50 py-16 px-16'>
            <HomePageContainer className="flex flex-col">
                {/* Titre principal */}
                <div
                    className={`flex items-center justify-center mb-4 transition-opacity duration-1000 ${
                        isVisible ? 'opacity-100 animate-fadeIn' : 'opacity-0'
                    }`}
                >
                    <Typography
                        variant='h2'
                        weight='bold'
                        className='drop-shadow-md'
                    >
                        Simplifiez la rédaction de vos documents RH
                    </Typography>
                </div>

                {/* Sous-titre */}
                <div
                    className={`flex items-center justify-center mb-12 transition-opacity duration-1000 ${
                        isVisible ? 'opacity-100 animate-slideUp' : 'opacity-0'
                    }`}
                >
                    <Typography variant='h3' weight='bold' className='drop-shadow-md'>
                        Bara Doc est l'outil qu'il vous faut !
                    </Typography>
                </div>

                {/* Cartes de bénéfices */}
                <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4 sm:gap-6 md:gap-8 items-stretch">
                    {benefits.map((benefit, index) => (
                        <div
                            key={index}
                            className={`transition-opacity duration-1000 delay-${index * 200} ${
                                isVisible ? 'opacity-100 animate-fadeIn' : 'opacity-0'
                            }`}
                        >
                            <Card
                                title={benefit.title}
                                description={benefit.description}
                                image={benefit.image}
                            />
                        </div>
                    ))}
                </div>


                {/* Bouton et sous-texte */}
                <div
                    className={`flex flex-col items-center justify-center mt-8 space-y-2 transition-opacity duration-1000 ${
                        isVisible ? 'opacity-100 animate-slideUp delay-200' : 'opacity-0'
                    }`}
                >
                    <Button 
                        variant='primary'
                        size="md"
                        onClick={() => navigate('/document-manager')}
                        className="transform hover:scale-105 transition-transform duration-300"
                    >
                        Découvrir Bara Doc
                    </Button>

                    <Typography
                        variant='caption3'
                        theme='gray-600'
                        className='italic'
                    >
                        Essayer Bara Doc gratuitement et sans inscription*
                    </Typography>
                </div>
            </HomePageContainer>
        </section>
    );
};

export default HomePageBenefits;
