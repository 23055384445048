import React from 'react';

function PdfPreview({ documentData }) {
    // Fonction pour extraire l'URL PDF
    const getDocumentPdfUrl = (data) => {
        const baseUrl = process.env.REACT_APP_API_URL;
        if (!data) return null;

        // Utiliser pdf_url ou générer une URL basée sur mongo_id
        if (data.pdf_url) {
            return `${baseUrl}${data.pdf_url.trim()}`;
        } else if (data.mongo_id) {
            return `${baseUrl}/mongo/${data.mongo_id.trim()}/pdf`;
        }

        return null; // Aucun PDF disponible
    };

    const pdfUrl = getDocumentPdfUrl(documentData);

    return (
        <>
            {pdfUrl ? (
                <iframe
                    src={pdfUrl}
                    title="Aperçu du document PDF"
                    className="h-full w-full border-none"
                />
            ) : (
                <p className="text-red-500 text-center p-4">
                    Aucun fichier PDF disponible pour ce document.
                </p>
            )}
        </>
    );
}

export default PdfPreview;
