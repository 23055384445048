import { Modal} from "flowbite-react";
import React from "react";

function SelectOptionModalContainer({ title, isOpen, onClose, children }) {
    return (
        <Modal show={isOpen} onClose={onClose} size="xl" dismissible={true} className="rounded">
            <Modal.Body>
                {children}
            </Modal.Body>
        </Modal>
    );
}

export default SelectOptionModalContainer;
