// src/context/Login/ModalContext.js
import React, { createContext, useContext, useState } from 'react';

const ModalContext = createContext();

export function ModalProvider({ children }) {
    const [isModalOpen, setModalOpen] = useState(false);
    const [modalType, setModalType] = useState(null);

    const openModal = (type = 'login') => {
        setModalType(type);
        setModalOpen(true);
    };
    
    const closeModal = () => {
        setModalOpen(false);
        setModalType(null);
    };

    const toggleModal = () => setModalOpen(prev => !prev);


    return (
        <ModalContext.Provider value={{ isModalOpen, modalType, openModal, closeModal }}>
            {children}
        </ModalContext.Provider>
    );
}

export function useModal() {
    return useContext(ModalContext);
}
