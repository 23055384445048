import React, { useState } from 'react';
import { HomePageContainer } from '../../../components/Containers/HomePageContainer';
import Typography from '../../../components/style/Typography';

const faqs = [
    { 
        question: "Comment fonctionne Bara Doc ?", 
        answer: "Bara Doc vous permet de générer des documents administratifs et RH en quelques clics grâce à des modèles personnalisables." 
    },
    { 
        question: "Puis-je utiliser le service gratuitement ?", 
        answer: "Oui, pendant la phase bêta, le service est entièrement gratuit." 
    },
    { 
        question: "Mes données sont-elles sécurisées ?", 
        answer: "Absolument. Nous utilisons les meilleures pratiques pour garantir la sécurité de vos données." 
    },
];

const HomePageFAQ = () => {
    const [openIndex, setOpenIndex] = useState(null);

    const toggleFAQ = (index) => {
        setOpenIndex(openIndex === index ? null : index);
    };

    return (
        <section className="py-16 bg-white">
            <HomePageContainer>
                <Typography variant="h2" className="text-center mb-8">Foire aux questions</Typography>
                <div className="space-y-6">
                    {faqs.map((faq, index) => (
                        <div 
                            key={index} 
                            className="border border-gray-200 rounded-lg p-4 cursor-pointer"
                            onClick={() => toggleFAQ(index)}
                        >
                            <Typography 
                                variant="h4" 
                                className="mb-2 flex justify-between items-center"
                            >
                                {faq.question}
                                <span className="ml-4">{openIndex === index ? "-" : "+"}</span>
                            </Typography>
                            {openIndex === index && (
                                <Typography variant="body-base" theme="gray">
                                    {faq.answer}
                                </Typography>
                            )}
                        </div>
                    ))}
                </div>
            </HomePageContainer>
        </section>
    );
};

export default HomePageFAQ;
