import React from 'react';
import Typography from '../Typography';

function ButtonSelect({ options, selectedValue, onSelect }) {
    return (
        <div className="w-full flex justify-center"> {/* Conteneur pour centrer le contenu */}
            <div className="w-[70%] flex space-x-2"> {/* Largeur de 70% pour les boutons */}
                {options.map((option, index) => (
                    <button
                        key={index}
                        type="button"
                        onClick={() => {
                            onSelect(option); // Passe l'option sélectionnée au parent
                        }}
                        className={`flex-1 relative rounded border p-4 cursor-pointer flex focus:outline-none 
                            ${selectedValue === option ? 'border-primary-400 bg-primary-200 shadow-md' : 'border-gray-300 bg-gray-50'} 
                            hover:bg-gray-200`}
                    >
                        <div className="flex items-center justify-between w-full">
                            {/* Texte de l'option avec Typography */}
                            <Typography
                                variant="caption3"
                                theme={selectedValue === option ? 'primary' : 'black'}
                                className={selectedValue === option ? 'font-semibold' : ''}
                            >
                                {option}
                            </Typography>                          
                        </div>
                    </button>
                ))}
            </div>
        </div>
    );
}

export default ButtonSelect;
