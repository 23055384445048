import React from 'react';
import { useUser } from '../../context/User/UserContext';

export const withRoleProtection = (Component, allowedRoles = []) => {
    return () => {
        const { user } = useUser();

        console.log('Utilisateur dans withRoleProtection :', user);

        if (!user || !user.global_role) {
            return (
                <div className="text-red-500 text-center">
                    Accès refusé. Vous n'avez pas les permissions nécessaires.
                </div>
            );
        }

        const userRole = user.global_role;

        if (!allowedRoles.includes(userRole)) {
            return (
                <div className="text-red-500 text-center">
                    Accès refusé. Vous n'avez pas les permissions nécessaires.
                </div>
            );
        }

        return <Component />;
    };
};

  
