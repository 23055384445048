import React, { useRef } from 'react';
import PdfPreview from '../../DocumentGeneration/DocumentGenerationUtilities/PdfPreview';

function PdfPreviewHomeModal({ isOpen, onClose, documentData }) {
    const modalRef = useRef();

    const handleOutsideClick = (e) => {
        if (modalRef.current && !modalRef.current.contains(e.target)) {
            onClose(); // Ferme la modal si on clique à l'extérieur
        }
    };

    return (
        isOpen && (
            <div
                className="fixed inset-0 bg-black bg-opacity-70 flex items-center justify-center z-50"
                onClick={handleOutsideClick}
            >
                <div
                    ref={modalRef}
                    className="relative max-w-4xl w-full h-full max-h-[90vh] bg-white rounded shadow-lg overflow-hidden"
                >
                    <PdfPreview documentData={documentData} />
                </div>
            </div>
        )
    );
}

export default PdfPreviewHomeModal;
