import clsx from 'clsx';

export const HowItWorksContainer = ({ children, className }) => {
    return (
        <div className={clsx(
            "max-w-7xl mx-auto px-6 py-12",
            className
        )}>
            {children}
        </div>
    );
};
