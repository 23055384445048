import clsx from "clsx";

export const DocumentManagerContainer = ({ children, className }) => {
    return (
        <div
            className={clsx(
                className,
                "max-w-7xl mx-auto h-[calc(100vh-4rem)] overflow-hidden flex flex-col"
            )}
        >
            {children}
        </div>
    );
};
