import React, { useState } from 'react';
import Button from '../../../../../style/Button';
import Typography from '../../../../../style/Typography';

import { MdDownload } from "react-icons/md";

function Logo({ name, value, onChange }) {
    const [fileName, setFileName] = useState(value?.fileName || ''); // Stocke le nom du fichier

    const handleFileChange = (event) => {
        const file = event.target.files[0];
        if (file) {
            setFileName(file.name); // Met à jour le nom du fichier
            const reader = new FileReader();
            reader.onload = () => {
                onChange(name, { fileName: file.name, fileContent: reader.result }); // Envoie le nom et le contenu Base64
            };
            reader.readAsDataURL(file); // Convertit en Base64
        }
    };

    return (
        <div className="mb-4 w-full flex flex-col items-center">
            {/* Ligne principale : Ajoutez votre logo + Bouton */}
            <div className="flex items-center w-[70%] justify-between">
                <Typography 
                    variant='body-base'
                    theme='gray-700'
                >
                    Sélection du logo :
                </Typography>
                <input
                    type="file"
                    accept="image/*"
                    onChange={handleFileChange} // Gestion du téléchargement
                    className="hidden" // Cache le champ fichier
                    id={`upload-${name}`} // Liaison unique entre input et label
                />
                <Button
                    type="button"
                    variant="tertiary"
                    size="sm"
                    iconSize={24}
                    className='w-24'
                    onClick={() => document.getElementById(`upload-${name}`).click()} // Simule un clic sur l'input fichier
                >
                    <MdDownload/>
                </Button>
            </div>
            {/* Ligne secondaire : Nom du fichier */}
            <div className="flex w-[70%] text-left px-2 mx-2">
                <Typography 
                    variant='caption4'
                    theme='gray-600'
                    className="truncate max-w-full text-ellipsis overflow-hidden italic"
                    title={fileName || '*Aucun logo sélectionné '}
                >
                    {fileName || '*Aucun logo sélectionné '}
                </Typography>
                {!fileName && <MdDownload className="text-gray-500" />}
            </div>
        </div>
    );
}

export default Logo;
