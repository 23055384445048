import React from 'react';
import { FiSettings, FiEdit, FiTrash2 } from 'react-icons/fi';

function AdminDocsTable({ models, onEdit, onDelete, onSettings }) {

    return (
        <table className="w-full border border-gray-300">
            <thead>
                <tr className="bg-gray-100">
                    <th className="p-2 text-center">Nom du modèle</th>
                    <th className="p-2 text-center">Catégorie</th>
                    <th className="p-2 text-center">Sous-catégorie</th>
                    <th className="p-2 text-center">Langue</th>
                    <th className="p-2 text-center">Date de création</th>
                    <th className="p-2 text-center">Action</th>
                </tr>
            </thead>
            <tbody>
                {models.map((model, index) => {
                    return (
                        <tr key={model.id || index} className="border-b">
                            <td>{model.name}</td>
                            <td>{model.category || "N/A"}</td>
                            <td>{model.subcategory || "N/A"}</td>
                            <td>{model.language || "N/A"}</td>
                            <td className="p-2 text-center">{model.created_at || "Date non disponible"}</td>
                            <td className="p-2 text-center">
                                <div className="flex items-center justify-center space-x-2">
                                    <button onClick={() => onSettings(model)} className="text-gray-600 hover:text-black">
                                        <FiSettings size={18} />
                                    </button>
                                    <button onClick={() => onEdit(model)} className="text-blue-600 hover:text-blue-800">
                                        <FiEdit size={18} />
                                    </button>
                                    <button onClick={() => onDelete(model)} className="text-red-600 hover:text-red-800">
                                        <FiTrash2 size={18} />
                                    </button>
                                </div>
                            </td>
                        </tr>
                    );
                })}
            </tbody>
        </table>
    );
}

export default AdminDocsTable;
