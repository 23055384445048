import React, { useState } from 'react';

function ButtonDropdown({ onGenerate }) {
  // Initialisation : Word est coché par défaut
  const [selectedOptions, setSelectedOptions] = useState({
    word: true,  // Word coché par défaut
    pdf: false,
  });

  const [isOpen, setIsOpen] = useState(false);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const handleCheckboxChange = (option) => {
    setSelectedOptions((prev) => ({
      ...prev,
      [option]: !prev[option],
    }));
  };

  const handleGenerateClick = () => {
    onGenerate(selectedOptions);
  };

  return (
    <div className="relative inline-flex">
      {/* Main Generate Button */}
      <button
        onClick={handleGenerateClick}
        className="text-white bg-primary hover:bg-primary-600 focus:ring-2 font-medium rounded-l text-sm px-4 py-2.5 text-center inline-flex items-center"
        type="button"
      >
        Générer
      </button>

      {/* Dropdown Button */}
      <button
        onClick={toggleDropdown}
        className="text-white bg-primary hover:bg-primary-600 focus:ring-2 font-medium rounded-r text-sm px-2.5 py-2.5"
        type="button"
      >
        <svg
          className="w-2.5 h-2.5"
          aria-hidden="true"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 10 6"
        >
          <path
            stroke="currentColor"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="M1 1l4 4 4-4"
          />
        </svg>
      </button>

      {/* Dropdown Menu */}
      {isOpen && (
        <div className="absolute right-0 z-10 bottom-full mb-2 w-48 bg-white rounded shadow-lg dark:bg-gray-700">
          <ul className="p-3 space-y-1 text-sm text-gray-700 dark:text-gray-200">
            <li>
              <div
                className="flex items-center p-2 rounded hover:bg-gray-100 dark:hover:bg-gray-600 cursor-pointer"
                onClick={() => handleCheckboxChange('word')}
              >
                <input
                  id="word-checkbox"
                  type="checkbox"
                  checked={selectedOptions.word}
                  onChange={() => handleCheckboxChange('word')}  // Modifier ici pour cocher/décocher via la case aussi
                  className="w-5 h-5 text-primary bg-gray-100 border-gray-300 rounded focus:ring-primary-600"
                />
                <label
                  htmlFor="word-checkbox"
                  className="ml-2 text-base font-medium text-gray-800"
                >
                  Version Word
                </label>
              </div>
            </li>
            <li>
              <div
                className="flex items-center p-2 rounded hover:bg-gray-100 dark:hover:bg-gray-600 cursor-pointer"
                onClick={() => handleCheckboxChange('pdf')}
              >
                <input
                  id="pdf-checkbox"
                  type="checkbox"
                  checked={selectedOptions.pdf}
                  onChange={() => handleCheckboxChange('pdf')}  // Modifier ici pour cocher/décocher via la case aussi
                  className="w-5 h-5 text-primary bg-gray-100 border-gray-300 rounded focus:ring-primary-600"
                />
                <label
                  htmlFor="pdf-checkbox"
                  className="ml-2 text-base font-medium text-gray-800"
                >
                  Version PDF
                </label>
              </div>
            </li>
          </ul>
        </div>
      )}
    </div>
  );
}

export default ButtonDropdown;
