import React from 'react';
import Typography from '../../../style/Typography';

function SideBarComponent({ icon, name, onSelect, isSidebarOpen, title, isActive }) {
    return (
        <div 
            onClick={onSelect} 
            className={`flex items-center ${
                isSidebarOpen ? 'space-x-2' : 'justify-center'
            } cursor-pointer p-2 transition-all duration-200 ${
                isActive
                    ? 'bg-primary-200 text-primary-600 rounded-md shadow-sm'
                    : 'text-gray-600 hover:bg-gray-100 hover:text-primary-600'
            }`}
            
            title={title} // Infobulle avec le texte complet
        >
            {/* Icône */}
            {icon && <span className="text-lg">{icon}</span>}
            
            {/* Texte */}
            {isSidebarOpen && (
                <Typography
                    variant="body-base" // Utilisez le style souhaité
                    theme={isActive ? 'primary' : 'gray'} // Couleur basée sur l'état actif
                    weight={isActive ? 'bold' : 'regular'} // Poids basé sur l'état actif
                    className="truncate overflow-hidden text-ellipsis" // Gère le débordement
                >
                    {name}
                </Typography>
            )}
        </div>
    );
}

export default SideBarComponent;
