import React, { useState, useEffect } from 'react';
import { updateUser } from '../../../utils/Api/ApiUsersUtilities';

function EditUserForm({ user, onSave, onCancel }) {
    const [name, setName] = useState(user.username);
    const [email, setEmail] = useState(user.mail);
    const [password, setPassword] = useState('');
    const [role, setRole] = useState(user.global_role);
    const [error, setError] = useState(null);  // Pour afficher les erreurs

    const roles = [
        { value: 'SUPERADMIN', label: 'SUPERADMIN' },
        { value: 'ADMIN', label: 'ADMIN' },
        { value: 'USER', label: 'USER' },
        { value: 'GUEST', label: 'GUEST' },
    ];
    
    useEffect(() => {
        setName(user.username);
        setEmail(user.mail);
        setRole(user.global_role);
        setPassword('');  // Le mot de passe reste vide si non modifié
    }, [user]);

    
    const handleSave = async () => {
        if (!roles.some((r) => r.value === role)) {
            setError('Rôle sélectionné invalide');
            return;
        }
    
        const updatedUser = {
            username: name,
            mail: email,
            password: password || user.password, // Renvoie le mot de passe actuel si non modifié
            global_role: role,
        };
    
        try {
            const result = await updateUser(user.id, updatedUser);
            onSave(result); // Notifie le parent avec les données mises à jour
        } catch (err) {
            setError(err.message); // Affiche l'erreur en cas d'échec
        }
    };
    
    
    

    return (
        <div className="space-y-4">
            <div>
                <label>Nom</label>
                <input
                    type="text"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    className="border p-2 rounded w-full"
                />
            </div>
            <div>
                <label>Email</label>
                <input
                    type="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    className="border p-2 rounded w-full"
                />
            </div>
            <div>
                <label>Mot de passe</label>
                <input
                    type="password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    className="border p-2 rounded w-full"
                    placeholder="Laissez vide pour ne pas modifier"
                />
            </div>
            <div>
                <label>Rôle</label>
                <select
                    value={role}
                    onChange={(e) => setRole(e.target.value)}
                    className="border p-2 rounded w-full"
                >
                    {roles.map((r) => (
                        <option key={r.value} value={r.value}>
                            {r.label}
                        </option>
                    ))}
                </select>
            </div>

            {error && <p style={{ color: 'red' }}>{error}</p>}
            <div className="flex justify-end space-x-2">
                <button onClick={handleSave} className="bg-blue-500 text-white p-2 rounded">
                    Enregistrer
                </button>
                <button onClick={onCancel} className="bg-gray-500 text-white p-2 rounded">
                    Annuler
                </button>
            </div>
        </div>
    );
}

export default EditUserForm;
